module.exports = {
  11: {
    current: "anatomy",
    border: { src: "active/B11.png", x: "239", y: "29", w: "63", h: "68" },
    anatomy: {
      src: "anatomy/A11.png",
      x: "239",
      y: "29",
      w: "63",
      h: "68",
      hasChild: false,
      child: "",
      stl: {
        name: "11_anatomy",
        url: "https://s3.eu-central-1.amazonaws.com/dentabula/3d_constructor/anatomy/view/11_anatomy.stl",
        options: { material: "enamel", opacity: 100 },
        progress: 0,
      },
    },
    missing: {
      src: "missing/E11.png",
      x: "239",
      y: "29",
      w: "63",
      h: "68",
      hasChild: false,
      child: "teeth",
      stl: {
        name: "11_missing",
        url: "https://s3.eu-central-1.amazonaws.com/dentabula/3d_constructor/missing/view/11_missing.stl",
        options: { material: "gum", opacity: 100 },
        progress: 0,
      },
    },
    reduced: {
      src: "reduced/R11.png",
      x: "242",
      y: "35",
      w: "60",
      h: "63",
      hasChild: false,
      child: "crown",
      stl: {
        name: "11_reduced",
        url: "https://s3.eu-central-1.amazonaws.com/dentabula/3d_constructor/reduced/view/11_reduced.stl",
        options: { material: "enamel", opacity: 100 },
        progress: 0,
      },
    },
    partically_reduced: {
      src: "partically_reduced/PR11.png",
      x: "240",
      y: "35",
      w: "62",
      h: "63",
      hasChild: false,
      child: "tab",
      stl: {
        name: "11_partically_reduced",
        url:
          "https://s3.eu-central-1.amazonaws.com/dentabula/3d_constructor/partically_reduced/view/11_partically_reduced.stl",
        options: { material: "enamel", opacity: 100 },
        progress: 0,
      },
    },
    tab: {
      stl: {
        name: "11_tab",
        url: "https://s3.eu-central-1.amazonaws.com/dentabula/3d_constructor/tab/view/11_tab.stl",
        options: { material: "green", opacity: 100 },
        progress: 0,
      },
    },
    crown: {
      stl: {
        name: "11_crown",
        url: "https://s3.eu-central-1.amazonaws.com/dentabula/3d_constructor/crown/view/11_crown.stl",
        options: { material: "green", opacity: 100 },
        progress: 0,
      },
    },
    teeth: {
      stl: {
        name: "11_teeth",
        url: "https://s3.eu-central-1.amazonaws.com/dentabula/3d_constructor/teeth/view/11_teeth.stl",
        options: { material: "green", opacity: 100 },
        progress: 0,
      },
    },
  },
  12: {
    current: "anatomy",
    border: { src: "active/B12.png", x: "194", y: "47", w: "49", h: "66" },
    anatomy: {
      src: "anatomy/A12.png",
      x: "194",
      y: "47",
      w: "49",
      h: "66",
      hasChild: false,
      child: "",
      stl: {
        name: "12_anatomy",
        url: "https://s3.eu-central-1.amazonaws.com/dentabula/3d_constructor/anatomy/view/12_anatomy.stl",
        options: { material: "enamel", opacity: 100 },
        progress: 0,
      },
    },
    missing: {
      src: "missing/E12.png",
      x: "194",
      y: "47",
      w: "49",
      h: "66",
      hasChild: false,
      child: "teeth",
      stl: {
        name: "12_missing",
        url: "https://s3.eu-central-1.amazonaws.com/dentabula/3d_constructor/missing/view/12_missing.stl",
        options: { material: "gum", opacity: 100 },
        progress: 0,
      },
    },
    reduced: {
      src: "reduced/R12.png",
      x: "200",
      y: "57",
      w: "42",
      h: "56",
      hasChild: false,
      child: "crown",
      stl: {
        name: "12_reduced",
        url: "https://s3.eu-central-1.amazonaws.com/dentabula/3d_constructor/reduced/view/12_reduced.stl",
        options: { material: "enamel", opacity: 100 },
        progress: 0,
      },
    },
    partically_reduced: {
      src: "partically_reduced/PR12.png",
      x: "196",
      y: "54",
      w: "47",
      h: "59",
      hasChild: false,
      child: "tab",
      stl: {
        name: "12_partically_reduced",
        url:
          "https://s3.eu-central-1.amazonaws.com/dentabula/3d_constructor/partically_reduced/view/12_partically_reduced.stl",
        options: { material: "enamel", opacity: 100 },
        progress: 0,
      },
    },
    tab: {
      stl: {
        name: "12_tab",
        url: "https://s3.eu-central-1.amazonaws.com/dentabula/3d_constructor/tab/view/12_tab.stl",
        options: { material: "green", opacity: 100 },
        progress: 0,
      },
    },
    crown: {
      stl: {
        name: "12_crown",
        url: "https://s3.eu-central-1.amazonaws.com/dentabula/3d_constructor/crown/view/12_crown.stl",
        options: { material: "green", opacity: 100 },
        progress: 0,
      },
    },
    teeth: {
      stl: {
        name: "12_teeth",
        url: "https://s3.eu-central-1.amazonaws.com/dentabula/3d_constructor/teeth/view/12_teeth.stl",
        options: { material: "green", opacity: 100 },
        progress: 0,
      },
    },
  },
  13: {
    current: "anatomy",
    border: { src: "active/B13.png", x: "158", y: "83", w: "60", h: "67" },
    anatomy: {
      src: "anatomy/A13.png",
      x: "158",
      y: "83",
      w: "60",
      h: "67",
      hasChild: false,
      child: "",
      stl: {
        name: "13_anatomy",
        url: "https://s3.eu-central-1.amazonaws.com/dentabula/3d_constructor/anatomy/view/13_anatomy.stl",
        options: { material: "enamel", opacity: 100 },
        progress: 0,
      },
    },
    missing: {
      src: "missing/E13.png",
      x: "158",
      y: "83",
      w: "60",
      h: "67",
      hasChild: false,
      child: "teeth",
      stl: {
        name: "13_missing",
        url: "https://s3.eu-central-1.amazonaws.com/dentabula/3d_constructor/missing/view/13_missing.stl",
        options: { material: "gum", opacity: 100 },
        progress: 0,
      },
    },
    reduced: {
      src: "reduced/R13.png",
      x: "166",
      y: "95",
      w: "52",
      h: "53",
      hasChild: false,
      child: "crown",
      stl: {
        name: "13_reduced",
        url: "https://s3.eu-central-1.amazonaws.com/dentabula/3d_constructor/reduced/view/13_reduced.stl",
        options: { material: "enamel", opacity: 100 },
        progress: 0,
      },
    },
    partically_reduced: {
      src: "partically_reduced/PR13.png",
      x: "166",
      y: "87",
      w: "52",
      h: "63",
      hasChild: false,
      child: "tab",
      stl: {
        name: "13_partically_reduced",
        url:
          "https://s3.eu-central-1.amazonaws.com/dentabula/3d_constructor/partically_reduced/view/13_partically_reduced.stl",
        options: { material: "enamel", opacity: 100 },
        progress: 0,
      },
    },
    tab: {
      stl: {
        name: "13_tab",
        url: "https://s3.eu-central-1.amazonaws.com/dentabula/3d_constructor/tab/view/13_tab.stl",
        options: { material: "green", opacity: 100 },
        progress: 0,
      },
    },
    crown: {
      stl: {
        name: "13_crown",
        url: "https://s3.eu-central-1.amazonaws.com/dentabula/3d_constructor/crown/view/13_crown.stl",
        options: { material: "green", opacity: 100 },
        progress: 0,
      },
    },
    teeth: {
      stl: {
        name: "13_teeth",
        url: "https://s3.eu-central-1.amazonaws.com/dentabula/3d_constructor/teeth/view/13_teeth.stl",
        options: { material: "green", opacity: 100 },
        progress: 0,
      },
    },
  },
  14: {
    current: "anatomy",
    border: { src: "active/B14.png", x: "140", y: "138", w: "66", h: "56" },
    anatomy: {
      src: "anatomy/A14.png",
      x: "140",
      y: "138",
      w: "66",
      h: "56",
      hasChild: false,
      child: "",
      stl: {
        name: "14_anatomy",
        url: "https://s3.eu-central-1.amazonaws.com/dentabula/3d_constructor/anatomy/view/14_anatomy.stl",
        options: { material: "enamel", opacity: 100 },
        progress: 0,
      },
    },
    missing: {
      src: "missing/E14.png",
      x: "140",
      y: "138",
      w: "66",
      h: "56",
      hasChild: false,
      child: "teeth",
      stl: {
        name: "14_missing",
        url: "https://s3.eu-central-1.amazonaws.com/dentabula/3d_constructor/missing/view/14_missing.stl",
        options: { material: "gum", opacity: 100 },
        progress: 0,
      },
    },
    reduced: {
      src: "reduced/R14.png",
      x: "148",
      y: "145",
      w: "56",
      h: "49",
      hasChild: false,
      child: "crown",
      stl: {
        name: "14_reduced",
        url: "https://s3.eu-central-1.amazonaws.com/dentabula/3d_constructor/reduced/view/14_reduced.stl",
        options: { material: "enamel", opacity: 100 },
        progress: 0,
      },
    },
    partically_reduced: {
      src: "partically_reduced/PR14.png",
      x: "140",
      y: "138",
      w: "65",
      h: "56",
      hasChild: false,
      child: "tab",
      stl: {
        name: "14_partically_reduced",
        url:
          "https://s3.eu-central-1.amazonaws.com/dentabula/3d_constructor/partically_reduced/view/14_partically_reduced.stl",
        options: { material: "enamel", opacity: 100 },
        progress: 0,
      },
    },
    tab: {
      stl: {
        name: "14_tab",
        url: "https://s3.eu-central-1.amazonaws.com/dentabula/3d_constructor/tab/view/14_tab.stl",
        options: { material: "green", opacity: 100 },
        progress: 0,
      },
    },
    crown: {
      stl: {
        name: "14_crown",
        url: "https://s3.eu-central-1.amazonaws.com/dentabula/3d_constructor/crown/view/14_crown.stl",
        options: { material: "green", opacity: 100 },
        progress: 0,
      },
    },
    teeth: {
      stl: {
        name: "14_teeth",
        url: "https://s3.eu-central-1.amazonaws.com/dentabula/3d_constructor/teeth/view/14_teeth.stl",
        options: { material: "green", opacity: 100 },
        progress: 0,
      },
    },
  },
  15: {
    current: "anatomy",
    border: { src: "active/B15.png", x: "121", y: "185", w: "73", h: "57" },
    anatomy: {
      src: "anatomy/A15.png",
      x: "121",
      y: "185",
      w: "73",
      h: "57",
      hasChild: false,
      child: "",
      stl: {
        name: "15_anatomy",
        url: "https://s3.eu-central-1.amazonaws.com/dentabula/3d_constructor/anatomy/view/15_anatomy.stl",
        options: { material: "enamel", opacity: 100 },
        progress: 0,
      },
    },
    missing: {
      src: "missing/E15.png",
      x: "121",
      y: "185",
      w: "73",
      h: "57",
      hasChild: false,
      child: "teeth",
      stl: {
        name: "15_missing",
        url: "https://s3.eu-central-1.amazonaws.com/dentabula/3d_constructor/missing/view/15_missing.stl",
        options: { material: "gum", opacity: 100 },
        progress: 0,
      },
    },
    reduced: {
      src: "reduced/R15.png",
      x: "128",
      y: "190",
      w: "65",
      h: "51",
      hasChild: false,
      child: "crown",
      stl: {
        name: "15_reduced",
        url: "https://s3.eu-central-1.amazonaws.com/dentabula/3d_constructor/reduced/view/15_reduced.stl",
        options: { material: "enamel", opacity: 100 },
        progress: 0,
      },
    },
    partically_reduced: {
      src: "partically_reduced/PR15.png",
      x: "121",
      y: "185",
      w: "73",
      h: "57",
      hasChild: false,
      child: "tab",
      stl: {
        name: "15_partically_reduced",
        url:
          "https://s3.eu-central-1.amazonaws.com/dentabula/3d_constructor/partically_reduced/view/15_partically_reduced.stl",
        options: { material: "enamel", opacity: 100 },
        progress: 0,
      },
    },
    tab: {
      stl: {
        name: "15_tab",
        url: "https://s3.eu-central-1.amazonaws.com/dentabula/3d_constructor/tab/view/15_tab.stl",
        options: { material: "green", opacity: 100 },
        progress: 0,
      },
    },
    crown: {
      stl: {
        name: "15_crown",
        url: "https://s3.eu-central-1.amazonaws.com/dentabula/3d_constructor/crown/view/15_crown.stl",
        options: { material: "green", opacity: 100 },
        progress: 0,
      },
    },
    teeth: {
      stl: {
        name: "15_teeth",
        url: "https://s3.eu-central-1.amazonaws.com/dentabula/3d_constructor/teeth/view/15_teeth.stl",
        options: { material: "green", opacity: 100 },
        progress: 0,
      },
    },
  },
  16: {
    current: "anatomy",
    border: { src: "active/B16.png", x: "105", y: "233", w: "84", h: "92" },
    anatomy: {
      src: "anatomy/A16.png",
      x: "105",
      y: "233",
      w: "84",
      h: "92",
      hasChild: false,
      child: "",
      stl: {
        name: "16_anatomy",
        url: "https://s3.eu-central-1.amazonaws.com/dentabula/3d_constructor/anatomy/view/16_anatomy.stl",
        options: { material: "enamel", opacity: 100 },
        progress: 0,
      },
    },
    missing: {
      src: "missing/E16.png",
      x: "105",
      y: "233",
      w: "84",
      h: "92",
      hasChild: false,
      child: "teeth",
      stl: {
        name: "16_missing",
        url: "https://s3.eu-central-1.amazonaws.com/dentabula/3d_constructor/missing/view/16_missing.stl",
        options: { material: "gum", opacity: 100 },
        progress: 0,
      },
    },
    reduced: {
      src: "reduced/R16.png",
      x: "109",
      y: "238",
      w: "80",
      h: "87",
      hasChild: false,
      child: "crown",
      stl: {
        name: "16_reduced",
        url: "https://s3.eu-central-1.amazonaws.com/dentabula/3d_constructor/reduced/view/16_reduced.stl",
        options: { material: "enamel", opacity: 100 },
        progress: 0,
      },
    },
    partically_reduced: {
      src: "partically_reduced/PR16.png",
      x: "108",
      y: "235",
      w: "84",
      h: "92",
      hasChild: false,
      child: "tab",
      stl: {
        name: "16_partically_reduced",
        url:
          "https://s3.eu-central-1.amazonaws.com/dentabula/3d_constructor/partically_reduced/view/16_partically_reduced.stl",
        options: { material: "enamel", opacity: 100 },
        progress: 0,
      },
    },
    tab: {
      stl: {
        name: "16_tab",
        url: "https://s3.eu-central-1.amazonaws.com/dentabula/3d_constructor/tab/view/16_tab.stl",
        options: { material: "green", opacity: 100 },
        progress: 0,
      },
    },
    crown: {
      stl: {
        name: "16_crown",
        url: "https://s3.eu-central-1.amazonaws.com/dentabula/3d_constructor/crown/view/16_crown.stl",
        options: { material: "green", opacity: 100 },
        progress: 0,
      },
    },
    teeth: {
      stl: {
        name: "16_teeth",
        url: "https://s3.eu-central-1.amazonaws.com/dentabula/3d_constructor/teeth/view/16_teeth.stl",
        options: { material: "green", opacity: 100 },
        progress: 0,
      },
    },
  },
  17: {
    current: "anatomy",
    border: { src: "active/B17.png", x: "95", y: "308", w: "77", h: "83" },
    anatomy: {
      src: "anatomy/A17.png",
      x: "95",
      y: "308",
      w: "77",
      h: "83",
      hasChild: false,
      child: "",
      stl: {
        name: "17_anatomy",
        url: "https://s3.eu-central-1.amazonaws.com/dentabula/3d_constructor/anatomy/view/17_anatomy.stl",
        options: { material: "enamel", opacity: 100 },
        progress: 0,
      },
    },
    missing: {
      src: "missing/E17.png",
      x: "95",
      y: "308",
      w: "77",
      h: "83",
      hasChild: false,
      child: "teeth",
      stl: {
        name: "17_missing",
        url: "https://s3.eu-central-1.amazonaws.com/dentabula/3d_constructor/missing/view/17_missing.stl",
        options: { material: "gum", opacity: 100 },
        progress: 0,
      },
    },
    reduced: {
      src: "reduced/R17.png",
      x: "99",
      y: "310",
      w: "73",
      h: "80",
      hasChild: false,
      child: "crown",
      stl: {
        name: "17_reduced",
        url: "https://s3.eu-central-1.amazonaws.com/dentabula/3d_constructor/reduced/view/17_reduced.stl",
        options: { material: "enamel", opacity: 100 },
        progress: 0,
      },
    },
    partically_reduced: {
      src: "partically_reduced/PR17.png",
      x: "94",
      y: "307",
      w: "77",
      h: "83",
      hasChild: false,
      child: "tab",
      stl: {
        name: "17_partically_reduced",
        url:
          "https://s3.eu-central-1.amazonaws.com/dentabula/3d_constructor/partically_reduced/view/17_partically_reduced.stl",
        options: { material: "enamel", opacity: 100 },
        progress: 0,
      },
    },
    tab: {
      stl: {
        name: "17_tab",
        url: "https://s3.eu-central-1.amazonaws.com/dentabula/3d_constructor/tab/view/17_tab.stl",
        options: { material: "green", opacity: 100 },
        progress: 0,
      },
    },
    crown: {
      stl: {
        name: "17_crown",
        url: "https://s3.eu-central-1.amazonaws.com/dentabula/3d_constructor/crown/view/17_crown.stl",
        options: { material: "green", opacity: 100 },
        progress: 0,
      },
    },
    teeth: {
      stl: {
        name: "17_teeth",
        url: "https://s3.eu-central-1.amazonaws.com/dentabula/3d_constructor/teeth/view/17_teeth.stl",
        options: { material: "green", opacity: 100 },
        progress: 0,
      },
    },
  },

  21: {
    current: "anatomy",
    border: { src: "active/B21.png", x: "300", y: "28", w: "65", h: "70" },
    anatomy: {
      src: "anatomy/A21.png",
      x: "300",
      y: "28",
      w: "65",
      h: "70",
      hasChild: false,
      child: "",
      stl: {
        name: "21_anatomy",
        url: "https://s3.eu-central-1.amazonaws.com/dentabula/3d_constructor/anatomy/view/21_anatomy.stl",
        options: { material: "enamel", opacity: 100 },
        progress: 0,
      },
    },
    missing: {
      src: "missing/E21.png",
      x: "300",
      y: "28",
      w: "65",
      h: "70",
      hasChild: false,
      child: "teeth",
      stl: {
        name: "21_missing",
        url: "https://s3.eu-central-1.amazonaws.com/dentabula/3d_constructor/missing/view/21_missing.stl",
        options: { material: "gum", opacity: 100 },
        progress: 0,
      },
    },
    reduced: {
      src: "reduced/R21.png",
      x: "301",
      y: "35",
      w: "59",
      h: "63",
      hasChild: false,
      child: "crown",
      stl: {
        name: "21_reduced",
        url: "https://s3.eu-central-1.amazonaws.com/dentabula/3d_constructor/reduced/view/21_reduced.stl",
        options: { material: "enamel", opacity: 100 },
        progress: 0,
      },
    },
    partically_reduced: {
      src: "partically_reduced/PR21.png",
      x: "299",
      y: "35",
      w: "64",
      h: "62",
      hasChild: false,
      child: "tab",
      stl: {
        name: "21_partically_reduced",
        url:
          "https://s3.eu-central-1.amazonaws.com/dentabula/3d_constructor/partically_reduced/view/21_partically_reduced.stl",
        options: { material: "enamel", opacity: 100 },
        progress: 0,
      },
    },
    tab: {
      stl: {
        name: "21_tab",
        url: "https://s3.eu-central-1.amazonaws.com/dentabula/3d_constructor/tab/view/21_tab.stl",
        options: { material: "green", opacity: 100 },
        progress: 0,
      },
    },
    crown: {
      stl: {
        name: "21_crown",
        url: "https://s3.eu-central-1.amazonaws.com/dentabula/3d_constructor/crown/view/21_crown.stl",
        options: { material: "green", opacity: 100 },
        progress: 0,
      },
    },
    teeth: {
      stl: {
        name: "21_teeth",
        url: "https://s3.eu-central-1.amazonaws.com/dentabula/3d_constructor/teeth/view/21_teeth.stl",
        options: { material: "green", opacity: 100 },
        progress: 0,
      },
    },
  },
  22: {
    current: "anatomy",
    border: { src: "active/B22.png", x: "359", y: "46", w: "51", h: "66" },
    anatomy: {
      src: "anatomy/A22.png",
      x: "359",
      y: "46",
      w: "51",
      h: "66",
      hasChild: false,
      child: "",
      stl: {
        name: "22_anatomy",
        url: "https://s3.eu-central-1.amazonaws.com/dentabula/3d_constructor/anatomy/view/22_anatomy.stl",
        options: { material: "enamel", opacity: 100 },
        progress: 0,
      },
    },
    missing: {
      src: "missing/E22.png",
      x: "359",
      y: "46",
      w: "51",
      h: "66",
      hasChild: false,
      child: "teeth",
      stl: {
        name: "22_missing",
        url: "https://s3.eu-central-1.amazonaws.com/dentabula/3d_constructor/missing/view/22_missing.stl",
        options: { material: "gum", opacity: 100 },
        progress: 0,
      },
    },
    reduced: {
      src: "reduced/R22.png",
      x: "361",
      y: "55",
      w: "43",
      h: "57",
      hasChild: false,
      child: "crown",
      stl: {
        name: "22_reduced",
        url: "https://s3.eu-central-1.amazonaws.com/dentabula/3d_constructor/reduced/view/22_reduced.stl",
        options: { material: "enamel", opacity: 100 },
        progress: 0,
      },
    },
    partically_reduced: {
      src: "partically_reduced/PR22.png",
      x: "360",
      y: "53",
      w: "46",
      h: "59",
      hasChild: false,
      child: "tab",
      stl: {
        name: "22_partically_reduced",
        url:
          "https://s3.eu-central-1.amazonaws.com/dentabula/3d_constructor/partically_reduced/view/22_partically_reduced.stl",
        options: { material: "enamel", opacity: 100 },
        progress: 0,
      },
    },
    tab: {
      stl: {
        name: "22_tab",
        url: "https://s3.eu-central-1.amazonaws.com/dentabula/3d_constructor/tab/view/22_tab.stl",
        options: { material: "green", opacity: 100 },
        progress: 0,
      },
    },
    crown: {
      stl: {
        name: "22_crown",
        url: "https://s3.eu-central-1.amazonaws.com/dentabula/3d_constructor/crown/view/22_crown.stl",
        options: { material: "green", opacity: 100 },
        progress: 0,
      },
    },
    teeth: {
      stl: {
        name: "22_teeth",
        url: "https://s3.eu-central-1.amazonaws.com/dentabula/3d_constructor/teeth/view/22_teeth.stl",
        options: { material: "green", opacity: 100 },
        progress: 0,
      },
    },
  },
  23: {
    current: "anatomy",
    border: { src: "active/B23.png", x: "385", y: "81", w: "59", h: "65" },
    anatomy: {
      src: "anatomy/A23.png",
      x: "385",
      y: "81",
      w: "59",
      h: "65",
      hasChild: false,
      child: "",
      stl: {
        name: "23_anatomy",
        url: "https://s3.eu-central-1.amazonaws.com/dentabula/3d_constructor/anatomy/view/23_anatomy.stl",
        options: { material: "enamel", opacity: 100 },
        progress: 0,
      },
    },
    missing: {
      src: "missing/E23.png",
      x: "385",
      y: "81",
      w: "59",
      h: "65",
      hasChild: false,
      child: "teeth",
      stl: {
        name: "23_missing",
        url: "https://s3.eu-central-1.amazonaws.com/dentabula/3d_constructor/missing/view/23_missing.stl",
        options: { material: "gum", opacity: 100 },
        progress: 0,
      },
    },
    reduced: {
      src: "reduced/R23.png",
      x: "386",
      y: "93",
      w: "52",
      h: "53",
      hasChild: false,
      child: "crown",
      stl: {
        name: "23_reduced",
        url: "https://s3.eu-central-1.amazonaws.com/dentabula/3d_constructor/reduced/view/23_reduced.stl",
        options: { material: "enamel", opacity: 100 },
        progress: 0,
      },
    },
    partically_reduced: {
      src: "partically_reduced/PR23.png",
      x: "384",
      y: "83",
      w: "53",
      h: "62",
      hasChild: false,
      child: "tab",
      stl: {
        name: "23_partically_reduced",
        url:
          "https://s3.eu-central-1.amazonaws.com/dentabula/3d_constructor/partically_reduced/view/23_partically_reduced.stl",
        options: { material: "enamel", opacity: 100 },
        progress: 0,
      },
    },
    tab: {
      stl: {
        name: "23_tab",
        url: "https://s3.eu-central-1.amazonaws.com/dentabula/3d_constructor/tab/view/23_tab.stl",
        options: { material: "green", opacity: 100 },
        progress: 0,
      },
    },
    crown: {
      stl: {
        name: "23_crown",
        url: "https://s3.eu-central-1.amazonaws.com/dentabula/3d_constructor/crown/view/23_crown.stl",
        options: { material: "green", opacity: 100 },
        progress: 0,
      },
    },
    teeth: {
      stl: {
        name: "23_teeth",
        url: "https://s3.eu-central-1.amazonaws.com/dentabula/3d_constructor/teeth/view/23_teeth.stl",
        options: { material: "green", opacity: 100 },
        progress: 0,
      },
    },
  },
  24: {
    current: "anatomy",
    border: { src: "active/B24.png", x: "398", y: "136", w: "67", h: "57" },
    anatomy: {
      src: "anatomy/A24.png",
      x: "398",
      y: "136",
      w: "67",
      h: "57",
      hasChild: false,
      child: "",
      stl: {
        name: "24_anatomy",
        url: "https://s3.eu-central-1.amazonaws.com/dentabula/3d_constructor/anatomy/view/24_anatomy.stl",
        options: { material: "enamel", opacity: 100 },
        progress: 0,
      },
    },
    missing: {
      src: "missing/E24.png",
      x: "398",
      y: "136",
      w: "67",
      h: "57",
      hasChild: false,
      child: "teeth",
      stl: {
        name: "24_missing",
        url: "https://s3.eu-central-1.amazonaws.com/dentabula/3d_constructor/missing/view/24_missing.stl",
        options: { material: "gum", opacity: 100 },
        progress: 0,
      },
    },
    reduced: {
      src: "reduced/R24.png",
      x: "401",
      y: "143",
      w: "56",
      h: "48",
      hasChild: false,
      child: "crown",
      stl: {
        name: "24_reduced",
        url: "https://s3.eu-central-1.amazonaws.com/dentabula/3d_constructor/reduced/view/24_reduced.stl",
        options: { material: "enamel", opacity: 100 },
        progress: 0,
      },
    },
    partically_reduced: {
      src: "partically_reduced/PR24.png",
      x: "398",
      y: "143",
      w: "59",
      h: "48",
      hasChild: false,
      child: "tab",
      stl: {
        name: "24_partically_reduced",
        url:
          "https://s3.eu-central-1.amazonaws.com/dentabula/3d_constructor/partically_reduced/view/24_partically_reduced.stl",
        options: { material: "enamel", opacity: 100 },
        progress: 0,
      },
    },
    tab: {
      stl: {
        name: "24_tab",
        url: "https://s3.eu-central-1.amazonaws.com/dentabula/3d_constructor/tab/view/24_tab.stl",
        options: { material: "green", opacity: 100 },
        progress: 0,
      },
    },
    crown: {
      stl: {
        name: "24_crown",
        url: "https://s3.eu-central-1.amazonaws.com/dentabula/3d_constructor/crown/view/24_crown.stl",
        options: { material: "green", opacity: 100 },
        progress: 0,
      },
    },
    teeth: {
      stl: {
        name: "24_teeth",
        url: "https://s3.eu-central-1.amazonaws.com/dentabula/3d_constructor/teeth/view/24_teeth.stl",
        options: { material: "green", opacity: 100 },
        progress: 0,
      },
    },
  },
  25: {
    current: "anatomy",
    border: { src: "active/B25.png", x: "410", y: "182", w: "73", h: "56" },
    anatomy: {
      src: "anatomy/A25.png",
      x: "410",
      y: "182",
      w: "73",
      h: "56",
      hasChild: false,
      child: "",
      stl: {
        name: "25_anatomy",
        url: "https://s3.eu-central-1.amazonaws.com/dentabula/3d_constructor/anatomy/view/25_anatomy.stl",
        options: { material: "enamel", opacity: 100 },
        progress: 0,
      },
    },
    missing: {
      src: "missing/E25.png",
      x: "410",
      y: "182",
      w: "73",
      h: "56",
      hasChild: false,
      child: "teeth",
      stl: {
        name: "25_missing",
        url: "https://s3.eu-central-1.amazonaws.com/dentabula/3d_constructor/missing/view/25_missing.stl",
        options: { material: "gum", opacity: 100 },
        progress: 0,
      },
    },
    reduced: {
      src: "reduced/R25.png",
      x: "413",
      y: "188",
      w: "65",
      h: "51",
      hasChild: false,
      child: "crown",
      stl: {
        name: "25_reduced",
        url: "https://s3.eu-central-1.amazonaws.com/dentabula/3d_constructor/reduced/view/25_reduced.stl",
        options: { material: "enamel", opacity: 100 },
        progress: 0,
      },
    },
    partically_reduced: {
      src: "partically_reduced/PR25.png",
      x: "411",
      y: "182",
      w: "72",
      h: "60",
      hasChild: false,
      child: "tab",
      stl: {
        name: "25_partically_reduced",
        url:
          "https://s3.eu-central-1.amazonaws.com/dentabula/3d_constructor/partically_reduced/view/25_partically_reduced.stl",
        options: { material: "enamel", opacity: 100 },
        progress: 0,
      },
    },
    tab: {
      stl: {
        name: "25_tab",
        url: "https://s3.eu-central-1.amazonaws.com/dentabula/3d_constructor/tab/view/25_tab.stl",
        options: { material: "green", opacity: 100 },
        progress: 0,
      },
    },
    crown: {
      stl: {
        name: "25_crown",
        url: "https://s3.eu-central-1.amazonaws.com/dentabula/3d_constructor/crown/view/25_crown.stl",
        options: { material: "green", opacity: 100 },
        progress: 0,
      },
    },
    teeth: {
      stl: {
        name: "25_teeth",
        url: "https://s3.eu-central-1.amazonaws.com/dentabula/3d_constructor/teeth/view/25_teeth.stl",
        options: { material: "green", opacity: 100 },
        progress: 0,
      },
    },
  },
  26: {
    current: "anatomy",
    border: { src: "active/B26.png", x: "418", y: "231", w: "83", h: "91" },
    anatomy: {
      src: "anatomy/A26.png",
      x: "418",
      y: "231",
      w: "83",
      h: "91",
      hasChild: false,
      child: "",
      stl: {
        name: "26_anatomy",
        url: "https://s3.eu-central-1.amazonaws.com/dentabula/3d_constructor/anatomy/view/26_anatomy.stl",
        options: { material: "enamel", opacity: 100 },
        progress: 0,
      },
    },
    missing: {
      src: "missing/E26.png",
      x: "418",
      y: "231",
      w: "83",
      h: "91",
      hasChild: false,
      child: "teeth",
      stl: {
        name: "26_missing",
        url: "https://s3.eu-central-1.amazonaws.com/dentabula/3d_constructor/missing/view/26_missing.stl",
        options: { material: "gum", opacity: 100 },
        progress: 0,
      },
    },
    reduced: {
      src: "reduced/R26.png",
      x: "418",
      y: "235",
      w: "80",
      h: "88",
      hasChild: false,
      child: "crown",
      stl: {
        name: "26_reduced",
        url: "https://s3.eu-central-1.amazonaws.com/dentabula/3d_constructor/reduced/view/26_reduced.stl",
        options: { material: "enamel", opacity: 100 },
        progress: 0,
      },
    },
    partically_reduced: {
      src: "partically_reduced/PR26.png",
      x: "417",
      y: "231",
      w: "85",
      h: "91",
      hasChild: false,
      child: "tab",
      stl: {
        name: "26_partically_reduced",
        url:
          "https://s3.eu-central-1.amazonaws.com/dentabula/3d_constructor/partically_reduced/view/26_partically_reduced.stl",
        options: { material: "enamel", opacity: 100 },
        progress: 0,
      },
    },
    tab: {
      stl: {
        name: "26_tab",
        url: "https://s3.eu-central-1.amazonaws.com/dentabula/3d_constructor/tab/view/26_tab.stl",
        options: { material: "green", opacity: 100 },
        progress: 0,
      },
    },
    crown: {
      stl: {
        name: "26_crown",
        url: "https://s3.eu-central-1.amazonaws.com/dentabula/3d_constructor/crown/view/26_crown.stl",
        options: { material: "green", opacity: 100 },
        progress: 0,
      },
    },
    teeth: {
      stl: {
        name: "26_teeth",
        url: "https://s3.eu-central-1.amazonaws.com/dentabula/3d_constructor/teeth/view/26_teeth.stl",
        options: { material: "green", opacity: 100 },
        progress: 0,
      },
    },
  },
  27: {
    current: "anatomy",
    border: { src: "active/B27.png", x: "435", y: "305", w: "78", h: "83" },
    anatomy: {
      src: "anatomy/A27.png",
      x: "435",
      y: "305",
      w: "78",
      h: "83",
      hasChild: false,
      child: "",
      stl: {
        name: "27_anatomy",
        url: "https://s3.eu-central-1.amazonaws.com/dentabula/3d_constructor/anatomy/view/27_anatomy.stl",
        options: { material: "enamel", opacity: 100 },
        progress: 0,
      },
    },
    missing: {
      src: "missing/E27.png",
      x: "435",
      y: "305",
      w: "78",
      h: "83",
      hasChild: false,
      child: "teeth",
      stl: {
        name: "27_missing",
        url: "https://s3.eu-central-1.amazonaws.com/dentabula/3d_constructor/missing/view/27_missing.stl",
        options: { material: "gum", opacity: 100 },
        progress: 0,
      },
    },
    reduced: {
      src: "reduced/R27.png",
      x: "436",
      y: "307",
      w: "72",
      h: "80",
      hasChild: false,
      child: "crown",
      stl: {
        name: "27_reduced",
        url: "https://s3.eu-central-1.amazonaws.com/dentabula/3d_constructor/reduced/view/27_reduced.stl",
        options: { material: "enamel", opacity: 100 },
        progress: 0,
      },
    },
    partically_reduced: {
      src: "partically_reduced/PR27.png",
      x: "435",
      y: "305",
      w: "78",
      h: "82",
      hasChild: false,
      child: "tab",
      stl: {
        name: "27_partically_reduced",
        url:
          "https://s3.eu-central-1.amazonaws.com/dentabula/3d_constructor/partically_reduced/view/27_partically_reduced.stl",
        options: { material: "enamel", opacity: 100 },
        progress: 0,
      },
    },
    tab: {
      stl: {
        name: "27_tab",
        url: "https://s3.eu-central-1.amazonaws.com/dentabula/3d_constructor/tab/view/27_tab.stl",
        options: { material: "green", opacity: 100 },
        progress: 0,
      },
    },
    crown: {
      stl: {
        name: "27_crown",
        url: "https://s3.eu-central-1.amazonaws.com/dentabula/3d_constructor/crown/view/27_crown.stl",
        options: { material: "green", opacity: 100 },
        progress: 0,
      },
    },
    teeth: {
      stl: {
        name: "27_teeth",
        url: "https://s3.eu-central-1.amazonaws.com/dentabula/3d_constructor/teeth/view/27_teeth.stl",
        options: { material: "green", opacity: 100 },
        progress: 0,
      },
    },
  },

  31: {
    current: "anatomy",
    border: { src: "active/B31.png", x: "308", y: "761", w: "39", h: "60" },
    anatomy: {
      src: "anatomy/A31.png",
      x: "308",
      y: "761",
      w: "39",
      h: "60",
      hasChild: false,
      child: "",
      stl: {
        name: "31_anatomy",
        url: "https://s3.eu-central-1.amazonaws.com/dentabula/3d_constructor/anatomy/view/31_anatomy.stl",
        options: { material: "enamel", opacity: 100 },
        progress: 0,
      },
    },
    missing: {
      src: "missing/E31.png",
      x: "308",
      y: "761",
      w: "39",
      h: "60",
      hasChild: false,
      child: "teeth",
      stl: {
        name: "31_missing",
        url: "https://s3.eu-central-1.amazonaws.com/dentabula/3d_constructor/missing/view/31_missing.stl",
        options: { material: "gum", opacity: 100 },
        progress: 0,
      },
    },
    reduced: {
      src: "reduced/R31.png",
      x: "307",
      y: "761",
      w: "36",
      h: "49",
      hasChild: false,
      child: "crown",
      stl: {
        name: "31_reduced",
        url: "https://s3.eu-central-1.amazonaws.com/dentabula/3d_constructor/reduced/view/31_reduced.stl",
        options: { material: "enamel", opacity: 100 },
        progress: 0,
      },
    },
    partically_reduced: {
      src: "partically_reduced/PR31.png",
      x: "306",
      y: "760",
      w: "40",
      h: "54",
      hasChild: false,
      child: "tab",
      stl: {
        name: "31_partically_reduced",
        url:
          "https://s3.eu-central-1.amazonaws.com/dentabula/3d_constructor/partically_reduced/view/31_partically_reduced.stl",
        options: { material: "enamel", opacity: 100 },
        progress: 0,
      },
    },
    tab: {
      stl: {
        name: "31_tab",
        url: "https://s3.eu-central-1.amazonaws.com/dentabula/3d_constructor/tab/view/31_tab.stl",
        options: { material: "green", opacity: 100 },
        progress: 0,
      },
    },
    crown: {
      stl: {
        name: "31_crown",
        url: "https://s3.eu-central-1.amazonaws.com/dentabula/3d_constructor/crown/view/31_crown.stl",
        options: { material: "green", opacity: 100 },
        progress: 0,
      },
    },
    teeth: {
      stl: {
        name: "31_teeth",
        url: "https://s3.eu-central-1.amazonaws.com/dentabula/3d_constructor/teeth/view/31_teeth.stl",
        options: { material: "green", opacity: 100 },
        progress: 0,
      },
    },
  },
  32: {
    current: "anatomy",
    border: { src: "active/B32.png", x: "342", y: "751", w: "46", h: "63" },
    anatomy: {
      src: "anatomy/A32.png",
      x: "342",
      y: "751",
      w: "46",
      h: "63",
      hasChild: false,
      child: "",
      stl: {
        name: "32_anatomy",
        url: "https://s3.eu-central-1.amazonaws.com/dentabula/3d_constructor/anatomy/view/32_anatomy.stl",
        options: { material: "enamel", opacity: 100 },
        progress: 0,
      },
    },
    missing: {
      src: "missing/E32.png",
      x: "342",
      y: "751",
      w: "46",
      h: "63",
      hasChild: false,
      child: "teeth",
      stl: {
        name: "32_missing",
        url: "https://s3.eu-central-1.amazonaws.com/dentabula/3d_constructor/missing/view/32_missing.stl",
        options: { material: "gum", opacity: 100 },
        progress: 0,
      },
    },
    reduced: {
      src: "reduced/R32.png",
      x: "343",
      y: "752",
      w: "36",
      h: "51",
      hasChild: false,
      child: "crown",
      stl: {
        name: "32_reduced",
        url: "https://s3.eu-central-1.amazonaws.com/dentabula/3d_constructor/reduced/view/32_reduced.stl",
        options: { material: "enamel", opacity: 100 },
        progress: 0,
      },
    },
    partically_reduced: {
      src: "partically_reduced/PR32.png",
      x: "343",
      y: "751",
      w: "42",
      h: "56",
      hasChild: false,
      child: "tab",
      stl: {
        name: "32_partically_reduced",
        url:
          "https://s3.eu-central-1.amazonaws.com/dentabula/3d_constructor/partically_reduced/view/32_partically_reduced.stl",
        options: { material: "enamel", opacity: 100 },
        progress: 0,
      },
    },
    tab: {
      stl: {
        name: "32_tab",
        url: "https://s3.eu-central-1.amazonaws.com/dentabula/3d_constructor/tab/view/32_tab.stl",
        options: { material: "green", opacity: 100 },
        progress: 0,
      },
    },
    crown: {
      stl: {
        name: "32_crown",
        url: "https://s3.eu-central-1.amazonaws.com/dentabula/3d_constructor/crown/view/32_crown.stl",
        options: { material: "green", opacity: 100 },
        progress: 0,
      },
    },
    teeth: {
      stl: {
        name: "32_teeth",
        url: "https://s3.eu-central-1.amazonaws.com/dentabula/3d_constructor/teeth/view/32_teeth.stl",
        options: { material: "green", opacity: 100 },
        progress: 0,
      },
    },
  },
  33: {
    current: "anatomy",
    border: { src: "active/B33.png", x: "369", y: "732", w: "53", h: "61" },
    anatomy: {
      src: "anatomy/A33.png",
      x: "369",
      y: "732",
      w: "53",
      h: "61",
      hasChild: false,
      child: "",
      stl: {
        name: "33_anatomy",
        url: "https://s3.eu-central-1.amazonaws.com/dentabula/3d_constructor/anatomy/view/33_anatomy.stl",
        options: { material: "enamel", opacity: 100 },
        progress: 0,
      },
    },
    missing: {
      src: "missing/E33.png",
      x: "369",
      y: "732",
      w: "53",
      h: "61",
      hasChild: false,
      child: "teeth",
      stl: {
        name: "33_missing",
        url: "https://s3.eu-central-1.amazonaws.com/dentabula/3d_constructor/missing/view/33_missing.stl",
        options: { material: "gum", opacity: 100 },
        progress: 0,
      },
    },
    reduced: {
      src: "reduced/R33.png",
      x: "372",
      y: "731",
      w: "47",
      h: "50",
      hasChild: false,
      child: "crown",
      stl: {
        name: "33_reduced",
        url: "https://s3.eu-central-1.amazonaws.com/dentabula/3d_constructor/reduced/view/33_reduced.stl",
        options: { material: "enamel", opacity: 100 },
        progress: 0,
      },
    },
    partically_reduced: {
      src: "partically_reduced/PR33.png",
      x: "370",
      y: "731",
      w: "47",
      h: "56",
      hasChild: false,
      child: "tab",
      stl: {
        name: "33_partically_reduced",
        url:
          "https://s3.eu-central-1.amazonaws.com/dentabula/3d_constructor/partically_reduced/view/33_partically_reduced.stl",
        options: { material: "enamel", opacity: 100 },
        progress: 0,
      },
    },
    tab: {
      stl: {
        name: "33_tab",
        url: "https://s3.eu-central-1.amazonaws.com/dentabula/3d_constructor/tab/view/33_tab.stl",
        options: { material: "green", opacity: 100 },
        progress: 0,
      },
    },
    crown: {
      stl: {
        name: "33_crown",
        url: "https://s3.eu-central-1.amazonaws.com/dentabula/3d_constructor/crown/view/33_crown.stl",
        options: { material: "green", opacity: 100 },
        progress: 0,
      },
    },
    teeth: {
      stl: {
        name: "33_teeth",
        url: "https://s3.eu-central-1.amazonaws.com/dentabula/3d_constructor/teeth/view/33_teeth.stl",
        options: { material: "green", opacity: 100 },
        progress: 0,
      },
    },
  },
  34: {
    current: "anatomy",
    border: { src: "active/B34.png", x: "397", y: "694", w: "58", h: "56" },
    anatomy: {
      src: "anatomy/A34.png",
      x: "397",
      y: "694",
      w: "58",
      h: "56",
      hasChild: false,
      child: "",
      stl: {
        name: "34_anatomy",
        url: "https://s3.eu-central-1.amazonaws.com/dentabula/3d_constructor/anatomy/view/34_anatomy.stl",
        options: { material: "enamel", opacity: 100 },
        progress: 0,
      },
    },
    missing: {
      src: "missing/E34.png",
      x: "397",
      y: "694",
      w: "58",
      h: "56",
      hasChild: false,
      child: "teeth",
      stl: {
        name: "34_missing",
        url: "https://s3.eu-central-1.amazonaws.com/dentabula/3d_constructor/missing/view/34_missing.stl",
        options: { material: "gum", opacity: 100 },
        progress: 0,
      },
    },
    reduced: {
      src: "reduced/R34.png",
      x: "400",
      y: "693",
      w: "51",
      h: "51",
      hasChild: false,
      child: "crown",
      stl: {
        name: "34_reduced",
        url: "https://s3.eu-central-1.amazonaws.com/dentabula/3d_constructor/reduced/view/34_reduced.stl",
        options: { material: "enamel", opacity: 100 },
        progress: 0,
      },
    },
    partically_reduced: {
      src: "partically_reduced/PR34.png",
      x: "398",
      y: "692",
      w: "54",
      h: "54",
      hasChild: false,
      child: "tab",
      stl: {
        name: "34_partically_reduced",
        url:
          "https://s3.eu-central-1.amazonaws.com/dentabula/3d_constructor/partically_reduced/view/34_partically_reduced.stl",
        options: { material: "enamel", opacity: 100 },
        progress: 0,
      },
    },
    tab: {
      stl: {
        name: "34_tab",
        url: "https://s3.eu-central-1.amazonaws.com/dentabula/3d_constructor/tab/view/34_tab.stl",
        options: { material: "green", opacity: 100 },
        progress: 0,
      },
    },
    crown: {
      stl: {
        name: "34_crown",
        url: "https://s3.eu-central-1.amazonaws.com/dentabula/3d_constructor/crown/view/34_crown.stl",
        options: { material: "green", opacity: 100 },
        progress: 0,
      },
    },
    teeth: {
      stl: {
        name: "34_teeth",
        url: "https://s3.eu-central-1.amazonaws.com/dentabula/3d_constructor/teeth/view/34_teeth.stl",
        options: { material: "green", opacity: 100 },
        progress: 0,
      },
    },
  },
  35: {
    current: "anatomy",
    border: { src: "active/B35.png", x: "407", y: "642", w: "67", h: "61" },
    anatomy: {
      src: "anatomy/A35.png",
      x: "407",
      y: "642",
      w: "67",
      h: "61",
      hasChild: false,
      child: "",
      stl: {
        name: "35_anatomy",
        url: "https://s3.eu-central-1.amazonaws.com/dentabula/3d_constructor/anatomy/view/35_anatomy.stl",
        options: { material: "enamel", opacity: 100 },
        progress: 0,
      },
    },
    missing: {
      src: "missing/E35.png",
      x: "407",
      y: "642",
      w: "67",
      h: "61",
      hasChild: false,
      child: "teeth",
      stl: {
        name: "35_missing",
        url: "https://s3.eu-central-1.amazonaws.com/dentabula/3d_constructor/missing/view/35_missing.stl",
        options: { material: "gum", opacity: 100 },
        progress: 0,
      },
    },
    reduced: {
      src: "reduced/R35.png",
      x: "414",
      y: "642",
      w: "58",
      h: "55",
      hasChild: false,
      child: "crown",
      stl: {
        name: "35_reduced",
        url: "https://s3.eu-central-1.amazonaws.com/dentabula/3d_constructor/reduced/view/35_reduced.stl",
        options: { material: "enamel", opacity: 100 },
        progress: 0,
      },
    },
    partically_reduced: {
      src: "partically_reduced/PR35.png",
      x: "408",
      y: "642",
      w: "66",
      h: "60",
      hasChild: false,
      child: "tab",
      stl: {
        name: "35_partically_reduced",
        url:
          "https://s3.eu-central-1.amazonaws.com/dentabula/3d_constructor/partically_reduced/view/35_partically_reduced.stl",
        options: { material: "enamel", opacity: 100 },
        progress: 0,
      },
    },
    tab: {
      stl: {
        name: "35_tab",
        url: "https://s3.eu-central-1.amazonaws.com/dentabula/3d_constructor/tab/view/35_tab.stl",
        options: { material: "green", opacity: 100 },
        progress: 0,
      },
    },
    crown: {
      stl: {
        name: "35_crown",
        url: "https://s3.eu-central-1.amazonaws.com/dentabula/3d_constructor/crown/view/35_crown.stl",
        options: { material: "green", opacity: 100 },
        progress: 0,
      },
    },
    teeth: {
      stl: {
        name: "35_teeth",
        url: "https://s3.eu-central-1.amazonaws.com/dentabula/3d_constructor/teeth/view/35_teeth.stl",
        options: { material: "green", opacity: 100 },
        progress: 0,
      },
    },
  },
  36: {
    current: "anatomy",
    border: { src: "active/B36.png", x: "417", y: "568", w: "85", h: "85" },
    anatomy: {
      src: "anatomy/A36.png",
      x: "417",
      y: "568",
      w: "85",
      h: "85",
      hasChild: false,
      child: "",
      stl: {
        name: "36_anatomy",
        url: "https://s3.eu-central-1.amazonaws.com/dentabula/3d_constructor/anatomy/view/36_anatomy.stl",
        options: { material: "enamel", opacity: 100 },
        progress: 0,
      },
    },
    missing: {
      src: "missing/E36.png",
      x: "417",
      y: "568",
      w: "85",
      h: "85",
      hasChild: false,
      child: "teeth",
      stl: {
        name: "36_missing",
        url: "https://s3.eu-central-1.amazonaws.com/dentabula/3d_constructor/missing/view/36_missing.stl",
        options: { material: "gum", opacity: 100 },
        progress: 0,
      },
    },
    reduced: {
      src: "reduced/R36.png",
      x: "424",
      y: "567",
      w: "78",
      h: "82",
      hasChild: false,
      child: "crown",
      stl: {
        name: "36_reduced",
        url: "https://s3.eu-central-1.amazonaws.com/dentabula/3d_constructor/reduced/view/36_reduced.stl",
        options: { material: "enamel", opacity: 100 },
        progress: 0,
      },
    },
    partically_reduced: {
      src: "partically_reduced/PR36.png",
      x: "418",
      y: "565",
      w: "86",
      h: "88",
      hasChild: false,
      child: "tab",
      stl: {
        name: "36_partically_reduced",
        url:
          "https://s3.eu-central-1.amazonaws.com/dentabula/3d_constructor/partically_reduced/view/36_partically_reduced.stl",
        options: { material: "enamel", opacity: 100 },
        progress: 0,
      },
    },
    tab: {
      stl: {
        name: "36_tab",
        url: "https://s3.eu-central-1.amazonaws.com/dentabula/3d_constructor/tab/view/36_tab.stl",
        options: { material: "green", opacity: 100 },
        progress: 0,
      },
    },
    crown: {
      stl: {
        name: "36_crown",
        url: "https://s3.eu-central-1.amazonaws.com/dentabula/3d_constructor/crown/view/36_crown.stl",
        options: { material: "green", opacity: 100 },
        progress: 0,
      },
    },
    teeth: {
      stl: {
        name: "36_teeth",
        url: "https://s3.eu-central-1.amazonaws.com/dentabula/3d_constructor/teeth/view/36_teeth.stl",
        options: { material: "green", opacity: 100 },
        progress: 0,
      },
    },
  },
  37: {
    current: "anatomy",
    border: { src: "active/B37.png", x: "435", y: "493", w: "86", h: "84" },
    anatomy: {
      src: "anatomy/A37.png",
      x: "435",
      y: "493",
      w: "86",
      h: "84",
      hasChild: false,
      child: "",
      stl: {
        name: "37_anatomy",
        url: "https://s3.eu-central-1.amazonaws.com/dentabula/3d_constructor/anatomy/view/37_anatomy.stl",
        options: { material: "enamel", opacity: 100 },
        progress: 0,
      },
    },
    missing: {
      src: "missing/E37.png",
      x: "435",
      y: "493",
      w: "86",
      h: "84",
      hasChild: false,
      child: "teeth",
      stl: {
        name: "37_missing",
        url: "https://s3.eu-central-1.amazonaws.com/dentabula/3d_constructor/missing/view/37_missing.stl",
        options: { material: "gum", opacity: 100 },
        progress: 0,
      },
    },
    reduced: {
      src: "reduced/R37.png",
      x: "445",
      y: "492",
      w: "75",
      h: "81",
      hasChild: false,
      child: "crown",
      stl: {
        name: "37_reduced",
        url: "https://s3.eu-central-1.amazonaws.com/dentabula/3d_constructor/reduced/view/37_reduced.stl",
        options: { material: "enamel", opacity: 100 },
        progress: 0,
      },
    },
    partically_reduced: {
      src: "partically_reduced/PR37.png",
      x: "436",
      y: "493",
      w: "85",
      h: "82",
      hasChild: false,
      child: "tab",
      stl: {
        name: "37_partically_reduced",
        url:
          "https://s3.eu-central-1.amazonaws.com/dentabula/3d_constructor/partically_reduced/view/37_partically_reduced.stl",
        options: { material: "enamel", opacity: 100 },
        progress: 0,
      },
    },
    tab: {
      stl: {
        name: "37_tab",
        url: "https://s3.eu-central-1.amazonaws.com/dentabula/3d_constructor/tab/view/37_tab.stl",
        options: { material: "green", opacity: 100 },
        progress: 0,
      },
    },
    crown: {
      stl: {
        name: "37_crown",
        url: "https://s3.eu-central-1.amazonaws.com/dentabula/3d_constructor/crown/view/37_crown.stl",
        options: { material: "green", opacity: 100 },
        progress: 0,
      },
    },
    teeth: {
      stl: {
        name: "37_teeth",
        url: "https://s3.eu-central-1.amazonaws.com/dentabula/3d_constructor/teeth/view/37_teeth.stl",
        options: { material: "green", opacity: 100 },
        progress: 0,
      },
    },
  },

  41: {
    current: "anatomy",
    border: { src: "active/B41.png", x: "268", y: "761", w: "41", h: "60" },
    anatomy: {
      src: "anatomy/A41.png",
      x: "268",
      y: "761",
      w: "41",
      h: "60",
      hasChild: false,
      child: "",
      stl: {
        name: "41_anatomy",
        url: "https://s3.eu-central-1.amazonaws.com/dentabula/3d_constructor/anatomy/view/41_anatomy.stl",
        options: { material: "enamel", opacity: 100 },
        progress: 0,
      },
    },
    missing: {
      src: "missing/E41.png",
      x: "268",
      y: "761",
      w: "41",
      h: "60",
      hasChild: false,
      child: "teeth",
      stl: {
        name: "41_missing",
        url: "https://s3.eu-central-1.amazonaws.com/dentabula/3d_constructor/missing/view/41_missing.stl",
        options: { material: "gum", opacity: 100 },
        progress: 0,
      },
    },
    reduced: {
      src: "reduced/R41.png",
      x: "272",
      y: "761",
      w: "36",
      h: "50",
      hasChild: false,
      child: "crown",
      stl: {
        name: "41_reduced",
        url: "https://s3.eu-central-1.amazonaws.com/dentabula/3d_constructor/reduced/view/41_reduced.stl",
        options: { material: "enamel", opacity: 100 },
        progress: 0,
      },
    },
    partically_reduced: {
      src: "partically_reduced/PR41.png",
      x: "269",
      y: "760",
      w: "40",
      h: "55",
      hasChild: false,
      child: "tab",
      stl: {
        name: "41_partically_reduced",
        url:
          "https://s3.eu-central-1.amazonaws.com/dentabula/3d_constructor/partically_reduced/view/41_partically_reduced.stl",
        options: { material: "enamel", opacity: 100 },
        progress: 0,
      },
    },
    tab: {
      stl: {
        name: "41_tab",
        url: "https://s3.eu-central-1.amazonaws.com/dentabula/3d_constructor/tab/view/41_tab.stl",
        options: { material: "green", opacity: 100 },
        progress: 0,
      },
    },
    crown: {
      stl: {
        name: "41_crown",
        url: "https://s3.eu-central-1.amazonaws.com/dentabula/3d_constructor/crown/view/41_crown.stl",
        options: { material: "green", opacity: 100 },
        progress: 0,
      },
    },
    teeth: {
      stl: {
        name: "41_teeth",
        url: "https://s3.eu-central-1.amazonaws.com/dentabula/3d_constructor/teeth/view/41_teeth.stl",
        options: { material: "green", opacity: 100 },
        progress: 0,
      },
    },
  },
  42: {
    current: "anatomy",
    border: { src: "active/B42.png", x: "227", y: "752", w: "45", h: "62" },
    anatomy: {
      src: "anatomy/A42.png",
      x: "227",
      y: "752",
      w: "45",
      h: "62",
      hasChild: false,
      child: "",
      stl: {
        name: "42_anatomy",
        url: "https://s3.eu-central-1.amazonaws.com/dentabula/3d_constructor/anatomy/view/42_anatomy.stl",
        options: { material: "enamel", opacity: 100 },
        progress: 0,
      },
    },
    missing: {
      src: "missing/E42.png",
      x: "227",
      y: "752",
      w: "45",
      h: "62",
      hasChild: false,
      child: "teeth",
      stl: {
        name: "42_missing",
        url: "https://s3.eu-central-1.amazonaws.com/dentabula/3d_constructor/missing/view/42_missing.stl",
        options: { material: "gum", opacity: 100 },
        progress: 0,
      },
    },
    reduced: {
      src: "reduced/R42.png",
      x: "236",
      y: "753",
      w: "36",
      h: "51",
      hasChild: false,
      child: "crown",
      stl: {
        name: "42_reduced",
        url: "https://s3.eu-central-1.amazonaws.com/dentabula/3d_constructor/reduced/view/42_reduced.stl",
        options: { material: "enamel", opacity: 100 },
        progress: 0,
      },
    },
    partically_reduced: {
      src: "partically_reduced/PR42.png",
      x: "230",
      y: "753",
      w: "42",
      h: "55",
      hasChild: false,
      child: "tab",
      stl: {
        name: "42_partically_reduced",
        url:
          "https://s3.eu-central-1.amazonaws.com/dentabula/3d_constructor/partically_reduced/view/42_partically_reduced.stl",
        options: { material: "enamel", opacity: 100 },
        progress: 0,
      },
    },
    tab: {
      stl: {
        name: "42_tab",
        url: "https://s3.eu-central-1.amazonaws.com/dentabula/3d_constructor/tab/view/42_tab.stl",
        options: { material: "green", opacity: 100 },
        progress: 0,
      },
    },
    crown: {
      stl: {
        name: "42_crown",
        url: "https://s3.eu-central-1.amazonaws.com/dentabula/3d_constructor/crown/view/42_crown.stl",
        options: { material: "green", opacity: 100 },
        progress: 0,
      },
    },
    teeth: {
      stl: {
        name: "42_teeth",
        url: "https://s3.eu-central-1.amazonaws.com/dentabula/3d_constructor/teeth/view/42_teeth.stl",
        options: { material: "green", opacity: 100 },
        progress: 0,
      },
    },
  },
  43: {
    current: "anatomy",
    border: { src: "active/B43.png", x: "194", y: "734", w: "51", h: "61" },
    anatomy: {
      src: "anatomy/A43.png",
      x: "194",
      y: "734",
      w: "51",
      h: "61",
      hasChild: false,
      child: "",
      stl: {
        name: "43_anatomy",
        url: "https://s3.eu-central-1.amazonaws.com/dentabula/3d_constructor/anatomy/view/43_anatomy.stl",
        options: { material: "enamel", opacity: 100 },
        progress: 0,
      },
    },
    missing: {
      src: "missing/E43.png",
      x: "194",
      y: "734",
      w: "51",
      h: "61",
      hasChild: false,
      child: "teeth",
      stl: {
        name: "43_missing",
        url: "https://s3.eu-central-1.amazonaws.com/dentabula/3d_constructor/missing/view/43_missing.stl",
        options: { material: "gum", opacity: 100 },
        progress: 0,
      },
    },
    reduced: {
      src: "reduced/R43.png",
      x: "196",
      y: "732",
      w: "47",
      h: "51",
      hasChild: false,
      child: "crown",
      stl: {
        name: "43_reduced",
        url: "https://s3.eu-central-1.amazonaws.com/dentabula/3d_constructor/reduced/view/43_reduced.stl",
        options: { material: "enamel", opacity: 100 },
        progress: 0,
      },
    },
    partically_reduced: {
      src: "partically_reduced/PR43.png",
      x: "196",
      y: "732",
      w: "49",
      h: "60",
      hasChild: false,
      child: "tab",
      stl: {
        name: "43_partically_reduced",
        url:
          "https://s3.eu-central-1.amazonaws.com/dentabula/3d_constructor/partically_reduced/view/43_partically_reduced.stl",
        options: { material: "enamel", opacity: 100 },
        progress: 0,
      },
    },
    tab: {
      stl: {
        name: "43_tab",
        url: "https://s3.eu-central-1.amazonaws.com/dentabula/3d_constructor/tab/view/43_tab.stl",
        options: { material: "green", opacity: 100 },
        progress: 0,
      },
    },
    crown: {
      stl: {
        name: "43_crown",
        url: "https://s3.eu-central-1.amazonaws.com/dentabula/3d_constructor/crown/view/43_crown.stl",
        options: { material: "green", opacity: 100 },
        progress: 0,
      },
    },
    teeth: {
      stl: {
        name: "43_teeth",
        url: "https://s3.eu-central-1.amazonaws.com/dentabula/3d_constructor/teeth/view/43_teeth.stl",
        options: { material: "green", opacity: 100 },
        progress: 0,
      },
    },
  },
  44: {
    current: "anatomy",
    border: { src: "active/B44.png", x: "159", y: "695", w: "57", h: "56" },
    anatomy: {
      src: "anatomy/A44.png",
      x: "159",
      y: "695",
      w: "57",
      h: "56",
      hasChild: false,
      child: "",
      stl: {
        name: "44_anatomy",
        url: "https://s3.eu-central-1.amazonaws.com/dentabula/3d_constructor/anatomy/view/44_anatomy.stl",
        options: { material: "enamel", opacity: 100 },
        progress: 0,
      },
    },
    missing: {
      src: "missing/E44.png",
      x: "159",
      y: "695",
      w: "57",
      h: "56",
      hasChild: false,
      child: "teeth",
      stl: {
        name: "44_missing",
        url: "https://s3.eu-central-1.amazonaws.com/dentabula/3d_constructor/missing/view/44_missing.stl",
        options: { material: "gum", opacity: 100 },
        progress: 0,
      },
    },
    reduced: {
      src: "reduced/R44.png",
      x: "163",
      y: "694",
      w: "50",
      h: "52",
      hasChild: false,
      child: "crown",
      stl: {
        name: "44_reduced",
        url: "https://s3.eu-central-1.amazonaws.com/dentabula/3d_constructor/reduced/view/44_reduced.stl",
        options: { material: "enamel", opacity: 100 },
        progress: 0,
      },
    },
    partically_reduced: {
      src: "partically_reduced/PR44.png",
      x: "158",
      y: "694",
      w: "58",
      h: "58",
      hasChild: false,
      child: "tab",
      stl: {
        name: "44_partically_reduced",
        url:
          "https://s3.eu-central-1.amazonaws.com/dentabula/3d_constructor/partically_reduced/view/44_partically_reduced.stl",
        options: { material: "enamel", opacity: 100 },
        progress: 0,
      },
    },
    tab: {
      stl: {
        name: "44_tab",
        url: "https://s3.eu-central-1.amazonaws.com/dentabula/3d_constructor/tab/view/44_tab.stl",
        options: { material: "green", opacity: 100 },
        progress: 0,
      },
    },
    crown: {
      stl: {
        name: "44_crown",
        url: "https://s3.eu-central-1.amazonaws.com/dentabula/3d_constructor/crown/view/44_crown.stl",
        options: { material: "green", opacity: 100 },
        progress: 0,
      },
    },
    teeth: {
      stl: {
        name: "44_teeth",
        url: "https://s3.eu-central-1.amazonaws.com/dentabula/3d_constructor/teeth/view/44_teeth.stl",
        options: { material: "green", opacity: 100 },
        progress: 0,
      },
    },
  },
  45: {
    current: "anatomy",
    border: { src: "active/B45.png", x: "140", y: "646", w: "65", h: "57" },
    anatomy: {
      src: "anatomy/A45.png",
      x: "140",
      y: "646",
      w: "65",
      h: "57",
      hasChild: false,
      child: "",
      stl: {
        name: "45_anatomy",
        url: "https://s3.eu-central-1.amazonaws.com/dentabula/3d_constructor/anatomy/view/45_anatomy.stl",
        options: { material: "enamel", opacity: 100 },
        progress: 0,
      },
    },
    missing: {
      src: "missing/E45.png",
      x: "140",
      y: "646",
      w: "65",
      h: "57",
      hasChild: false,
      child: "teeth",
      stl: {
        name: "45_missing",
        url: "https://s3.eu-central-1.amazonaws.com/dentabula/3d_constructor/missing/view/45_missing.stl",
        options: { material: "gum", opacity: 100 },
        progress: 0,
      },
    },
    reduced: {
      src: "reduced/R45.png",
      x: "141",
      y: "645",
      w: "59",
      h: "54",
      hasChild: false,
      child: "crown",
      stl: {
        name: "45_reduced",
        url: "https://s3.eu-central-1.amazonaws.com/dentabula/3d_constructor/reduced/view/45_reduced.stl",
        options: { material: "enamel", opacity: 100 },
        progress: 0,
      },
    },
    partically_reduced: {
      src: "partically_reduced/PR45.png",
      x: "140",
      y: "645",
      w: "64",
      h: "60",
      hasChild: false,
      child: "tab",
      stl: {
        name: "45_partically_reduced",
        url:
          "https://s3.eu-central-1.amazonaws.com/dentabula/3d_constructor/partically_reduced/view/45_partically_reduced.stl",
        options: { material: "enamel", opacity: 100 },
        progress: 0,
      },
    },
    tab: {
      stl: {
        name: "45_tab",
        url: "https://s3.eu-central-1.amazonaws.com/dentabula/3d_constructor/tab/view/45_tab.stl",
        options: { material: "green", opacity: 100 },
        progress: 0,
      },
    },
    crown: {
      stl: {
        name: "45_crown",
        url: "https://s3.eu-central-1.amazonaws.com/dentabula/3d_constructor/crown/view/45_crown.stl",
        options: { material: "green", opacity: 100 },
        progress: 0,
      },
    },
    teeth: {
      stl: {
        name: "45_teeth",
        url: "https://s3.eu-central-1.amazonaws.com/dentabula/3d_constructor/teeth/view/45_teeth.stl",
        options: { material: "green", opacity: 100 },
        progress: 0,
      },
    },
  },
  46: {
    current: "anatomy",
    border: { src: "active/B46.png", x: "109", y: "569", w: "87", h: "88" },
    anatomy: {
      src: "anatomy/A46.png",
      x: "109",
      y: "569",
      w: "87",
      h: "88",
      hasChild: false,
      child: "",
      stl: {
        name: "46_anatomy",
        url: "https://s3.eu-central-1.amazonaws.com/dentabula/3d_constructor/anatomy/view/46_anatomy.stl",
        options: { material: "enamel", opacity: 100 },
        progress: 0,
      },
    },
    missing: {
      src: "missing/E46.png",
      x: "109",
      y: "569",
      w: "87",
      h: "88",
      hasChild: false,
      child: "teeth",
      stl: {
        name: "46_missing",
        url: "https://s3.eu-central-1.amazonaws.com/dentabula/3d_constructor/missing/view/46_missing.stl",
        options: { material: "gum", opacity: 100 },
        progress: 0,
      },
    },
    reduced: {
      src: "reduced/R46.png",
      x: "110",
      y: "570",
      w: "78",
      h: "82",
      hasChild: false,
      child: "crown",
      stl: {
        name: "46_reduced",
        url: "https://s3.eu-central-1.amazonaws.com/dentabula/3d_constructor/reduced/view/46_reduced.stl",
        options: { material: "enamel", opacity: 100 },
        progress: 0,
      },
    },
    partically_reduced: {
      src: "partically_reduced/PR46.png",
      x: "109",
      y: "569",
      w: "87",
      h: "87",
      hasChild: false,
      child: "tab",
      stl: {
        name: "46_partically_reduced",
        url:
          "https://s3.eu-central-1.amazonaws.com/dentabula/3d_constructor/partically_reduced/view/46_partically_reduced.stl",
        options: { material: "enamel", opacity: 100 },
        progress: 0,
      },
    },
    tab: {
      stl: {
        name: "46_tab",
        url: "https://s3.eu-central-1.amazonaws.com/dentabula/3d_constructor/tab/view/46_tab.stl",
        options: { material: "green", opacity: 100 },
        progress: 0,
      },
    },
    crown: {
      stl: {
        name: "46_crown",
        url: "https://s3.eu-central-1.amazonaws.com/dentabula/3d_constructor/crown/view/46_crown.stl",
        options: { material: "green", opacity: 100 },
        progress: 0,
      },
    },
    teeth: {
      stl: {
        name: "46_teeth",
        url: "https://s3.eu-central-1.amazonaws.com/dentabula/3d_constructor/teeth/view/46_teeth.stl",
        options: { material: "green", opacity: 100 },
        progress: 0,
      },
    },
  },
  47: {
    current: "anatomy",
    border: { src: "active/B47.png", x: "90", y: "495", w: "84", h: "84" },
    anatomy: {
      src: "anatomy/A47.png",
      x: "90",
      y: "495",
      w: "84",
      h: "84",
      hasChild: false,
      child: "",
      stl: {
        name: "47_anatomy",
        url: "https://s3.eu-central-1.amazonaws.com/dentabula/3d_constructor/anatomy/view/47_anatomy.stl",
        options: { material: "enamel", opacity: 100 },
        progress: 0,
      },
    },
    missing: {
      src: "missing/E47.png",
      x: "90",
      y: "495",
      w: "84",
      h: "84",
      hasChild: false,
      child: "teeth",
      stl: {
        name: "47_missing",
        url: "https://s3.eu-central-1.amazonaws.com/dentabula/3d_constructor/missing/view/47_missing.stl",
        options: { material: "gum", opacity: 100 },
        progress: 0,
      },
    },
    reduced: {
      src: "reduced/R47.png",
      x: "91",
      y: "496",
      w: "75",
      h: "81",
      hasChild: false,
      child: "crown",
      stl: {
        name: "47_reduced",
        url: "https://s3.eu-central-1.amazonaws.com/dentabula/3d_constructor/reduced/view/47_reduced.stl",
        options: { material: "enamel", opacity: 100 },
        progress: 0,
      },
    },
    partically_reduced: {
      src: "partically_reduced/PR47.png",
      x: "90",
      y: "496",
      w: "86",
      h: "83",
      hasChild: false,
      child: "tab",
      stl: {
        name: "47_partically_reduced",
        url:
          "https://s3.eu-central-1.amazonaws.com/dentabula/3d_constructor/partically_reduced/view/47_partically_reduced.stl",
        options: { material: "enamel", opacity: 100 },
        progress: 0,
      },
    },
    tab: {
      stl: {
        name: "47_tab",
        url: "https://s3.eu-central-1.amazonaws.com/dentabula/3d_constructor/tab/view/47_tab.stl",
        options: { material: "green", opacity: 100 },
        progress: 0,
      },
    },
    crown: {
      stl: {
        name: "47_crown",
        url: "https://s3.eu-central-1.amazonaws.com/dentabula/3d_constructor/crown/view/47_crown.stl",
        options: { material: "green", opacity: 100 },
        progress: 0,
      },
    },
    teeth: {
      stl: {
        name: "47_teeth",
        url: "https://s3.eu-central-1.amazonaws.com/dentabula/3d_constructor/teeth/view/47_teeth.stl",
        options: { material: "green", opacity: 100 },
        progress: 0,
      },
    },
  },
};
