<template>
  <transition name="modal">
    <div class="modal-mask" v-on:click="$emit('close')">
      <div class="modal-wrapper modal-mini stlDownloadModal" v-on:click.stop>
        <div class="modal-container">
          <div class="modal-header">
            <div class="modal-header-icon">
              <icon name="hdd" scale="2"></icon>
            </div>
            <div class="modal-header-title">Список файлов для скачивания</div>
            <div class="modal-close" @click="$emit('close')">
              <icon name="times"></icon>
            </div>
          </div>
          <div class="modal-body">
            <ul class="filesList" v-if="downloadList.length">
              <li v-for="(item, index) in downloadList" :key="index">
                <a :href="item.url" download>
                  <span>{{ item.name }}</span>
                  <icon name="cloud-download-alt" scale="1.3"></icon>
                </a>
              </li>
            </ul>
            <div class="emptyStlFiles" v-else>
              Файлы для выгрузки отсутствуют
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "StlDownloadModal",
  props: ["downloadList"],
  data() {
    return {};
  },
  created() {
    console.log(this.downloadList);
  },
};
</script>

<style lang="sass">
@import "./modalStyle.sass"
.stlDownloadModal
	background-color: #00ab9e
	& ul.filesList
		display: flex
		flex-direction: column
		margin: 0
		padding: 0.5rem 1rem
		list-style: none
		& li a
			display: flex
			align-items: center
			justify-content: space-between
			width: 100%
			padding: 0.5rem 0.5rem
			color: #fff
			&:hover
				background: #009688
	& .emptyStlFiles
		text-align: center
		color: #fff
		padding: 2rem
</style>
