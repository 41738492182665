<template>
  <div id="viewer3Dconstructor">
    <div class="controls">
      <div class="viewer3Dlogo">
        <img src="./../../assets/images/viewer3DConstructorLogo.png" alt="" />
      </div>
      <div class="selectModel" v-if="!constuctorTop.isActive && !constuctorBottom.isActive">
        <img src="./../../assets/images/manipulator/selectModelIcon.png" alt="" />
        <span>Выберите модель</span>
      </div>
      <div
        class="downloadAll"
        v-on:click="getCurrentFiles()"
        v-if="isLoggedIn && isPremium && (constuctorTop.isActive || constuctorBottom.isActive)"
      >
        <img src="./../../assets/images/manipulator/downloadIcon.png" alt="" />
        <span>Скачать модель в STL</span>
      </div>
      <div
        class="permissionDenied"
        v-if="(!isLoggedIn || !isPremium) && (constuctorTop.isActive || constuctorBottom.isActive)"
      >
        <img src="./../../assets/images/manipulator/downloadIcon.png" alt="" />
        <span>Для скачивания модели необходим <a href="#">премиум статус</a></span>
      </div>
      <a href="#" class="viewer3Dclose" v-on:click.prevent.stop="$router.push({ name: 'demoModels' })">
        <icon name="times" scale="1.8"></icon>
      </a>
    </div>
    <div class="manipulator" :class="{ active: isFileControls }" :style="{ width: '260px' }">
      <a href="#" class="manipulatorToogle" v-on:click.prevent.stop="isFileControls = !isFileControls">
        <icon name="caret-right" v-if="!isFileControls" class="leftIcon" scale="1.5"></icon>
        <icon name="caret-down" v-if="isFileControls" class="leftIcon" scale="1.5"></icon>
        <span>Манипулятор</span>
      </a>
      <div class="manipulatorControls" v-if="isFileControls">
        <div class="visualForm">
          <svg
            class="visualFormContainer"
            :style="{
              width: (scale * (constuctorTop.width + constuctorBottom.width)) / 2,
              height: scale * (constuctorTop.height + constuctorBottom.height),
            }"
          >
            <image
              v-on:click="(constuctorTop.isActive = !constuctorTop.isActive), rerender()"
              :href="require('./../../assets/images/manipulator/' + constuctorTop.src)"
              :width="scale * constuctorTop.width"
              :height="scale * constuctorTop.height"
              class="vfT"
              :class="{ active: constuctorTop.isActive }"
            />
            <image
              v-on:click="(constuctorBottom.isActive = !constuctorBottom.isActive), rerender()"
              :href="require('./../../assets/images/manipulator/' + constuctorBottom.src)"
              :width="scale * constuctorBottom.width"
              :height="scale * constuctorBottom.height"
              :y="scale * constuctorBottom.offset"
              class="vfB"
              :class="{ active: constuctorBottom.isActive }"
            />
            <image
              v-for="(el, index) in constuctorItems"
              v-on:click="(currentSelect = index), activateMainModel()"
              :href="require('./../../assets/images/manipulator/' + el[el.current].src)"
              :x="scale * el[el.current].x"
              :y="scale * el[el.current].y"
              :width="scale * el[el.current].w"
              :height="scale * el[el.current].h"
              :key="index"
              class="toothItem"
            />
            <image
              v-for="(el, index) in constuctorItemsActive"
              :href="require('./../../assets/images/manipulator/' + el.border.src)"
              :x="scale * el.border.x"
              :y="scale * el.border.y"
              :width="scale * el.border.w"
              :height="scale * el.border.h"
              :key="'border_' + index"
            />
          </svg>
        </div>
        <div class="staticForm">
          <div class="jawSelect">
            <div class="jawSelectBtn" v-on:click="(constuctorTop.isActive = !constuctorTop.isActive), rerender()">
              <label for="topBG">Верхняя челюсть</label>
              <icon v-if="!constuctorTop.isActive" name="square"></icon>
              <icon v-if="constuctorTop.isActive" name="check-square"></icon>
            </div>
            <div class="jawSelectBtn" v-on:click="(constuctorBottom.isActive = !constuctorBottom.isActive), rerender()">
              <label for="bottomBG">Нижняя челюсть</label>
              <icon v-if="!constuctorBottom.isActive" name="square"></icon>
              <icon v-if="constuctorBottom.isActive" name="check-square"></icon>
            </div>
          </div>

          <div class="toothSelect">
            <label for="currentTeeth">Зуб</label>
            <select id="currentTeeth" v-model="currentSelect" v-on:change="activateMainModel()">
              <option :value="''">Не выбрано</option>
              <option :value="index" v-for="(item, index) in constuctorItems" :key="'select' + index">
                {{ index }}
              </option>
            </select>
          </div>
          <div class="constructorTypeBtn" v-if="currentSelect">
            <div
              class="constructorTypeBtnMain singleBtn"
              v-on:click="selectConstructorItem('anatomy')"
              :class="{ active: constuctorItems[currentSelect].current === 'anatomy' }"
            >
              <div class="constructorTypeBtnTitle">
                <img class="toothSelectIcon" src="./../../assets/images/manipulator/icon_anatomy.png" alt="" />
                <span for="bottomBG">Полная анатомия</span>
              </div>
            </div>
          </div>
          <div class="constructorTypeBtn" v-if="currentSelect">
            <div
              class="constructorTypeBtnMain"
              v-on:click="selectConstructorItem('missing')"
              :class="{ active: constuctorItems[currentSelect].current === 'missing' }"
            >
              <div class="constructorTypeBtnTitle">
                <img class="toothSelectIcon" src="./../../assets/images/manipulator/icon_missing.png" alt="" />
                <span for="bottomBG">Отсутствующий зуб</span>
              </div>
            </div>
            <div class="constructorTypeBtnChild" v-on:click="selectConstructorItemChild('missing')">
              <span>С зубом</span>
              <icon v-if="!constuctorItems[currentSelect]['missing'].hasChild" name="square"></icon>
              <icon v-if="constuctorItems[currentSelect]['missing'].hasChild" name="check-square"></icon>
            </div>
          </div>
          <div class="constructorTypeBtn" v-if="currentSelect">
            <div
              class="constructorTypeBtnMain"
              v-on:click="selectConstructorItem('reduced')"
              :class="{ active: constuctorItems[currentSelect].current === 'reduced' }"
            >
              <div class="constructorTypeBtnTitle">
                <img class="toothSelectIcon" src="./../../assets/images/manipulator/icon_reduced.png" alt="" />
                <span for="bottomBG">Редуцируемый зуб</span>
              </div>
            </div>
            <div class="constructorTypeBtnChild" v-on:click="selectConstructorItemChild('reduced')">
              <span>С коронкой</span>
              <icon v-if="!constuctorItems[currentSelect]['reduced'].hasChild" name="square"></icon>
              <icon v-if="constuctorItems[currentSelect]['reduced'].hasChild" name="check-square"></icon>
            </div>
          </div>
          <div class="constructorTypeBtn" v-if="currentSelect">
            <div
              class="constructorTypeBtnMain"
              v-on:click="selectConstructorItem('partically_reduced')"
              :class="{ active: constuctorItems[currentSelect].current === 'partically_reduced' }"
            >
              <div class="constructorTypeBtnTitle">
                <img
                  class="toothSelectIcon"
                  src="./../../assets/images/manipulator/icon_partically_reduced.png"
                  alt=""
                />
                <span>Частичная редукция</span>
              </div>
            </div>
            <div class="constructorTypeBtnChild" v-on:click="selectConstructorItemChild('partically_reduced')">
              <span>С вкладкой</span>
              <icon v-if="!constuctorItems[currentSelect]['partically_reduced'].hasChild" name="square"></icon>
              <icon v-if="constuctorItems[currentSelect]['partically_reduced'].hasChild" name="check-square"></icon>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="fileListControls" :class="{ active: isFileListControls }">
      <a href="#" class="fileListToogle" v-on:click.prevent.stop="isFileListControls = !isFileListControls">
        <img src="./../../assets/images/viewer3DIcon.png" class="leftIcon" alt="" />
        <span>Список файлов</span>
        <icon name="caret-left" v-if="!isFileListControls" class="rightIcon" scale="1.5"></icon>
        <icon name="caret-down" v-if="isFileListControls" class="rightIcon" scale="1.5"></icon>
      </a>
      <div class="fileListElements" v-if="isFileListControls">
        <div class="fileListItem" v-for="(item, index) in stlFiles" :key="index">
          <div class="fileListItemOpacity">
            <div class="opacityIcon opacity_0"></div>
            <input
              type="range"
              min="0"
              max="100"
              class="opacityType"
              :data-model-name="item.name"
              v-model="item.options.opacity"
              v-on:input="selectOpacity"
            />
            <div class="opacityIcon opacity_100"></div>
          </div>
          <span>{{ item.name }}</span>
        </div>
        <div v-if="stlFiles.length === 0" class="emptyFiles">
          <span>Нет загруженных файлов</span>
        </div>
      </div>
    </div>
    <div id="rendererContainer" ref="rendererContainer">
      <div id="dentalLoader" class="loaderFade" v-if="!isLoading">
        <div class="dentalBotLoader">
          <div class="dentalBotLoader-loader"></div>
          <!-- <img src="../../assets/images/3dViewer/bot_loader.png" class="dentalBotLoader-image" alt="colleagial loader"> -->
        </div>
      </div>
      <preloader class="constructorLoader" :progress="loadingProgress"></preloader>
      <wattermark></wattermark>
    </div>
    <stlDownloadModal
      v-if="isStlDownload"
      @close="isStlDownload = false"
      :downloadList="stlFilesDownload"
    ></stlDownloadModal>
  </div>
</template>

<script>
import preloader from "./../preloader/preloader.vue";
import wattermark from "./../wattermark/wattermark.vue";
import * as THREE from "three";
import models from "./models";
import stlDownloadModal from "./../modals/stlDownloadModal.vue";

const isWebglEnabled = require("detector-webgl");

const STLLoader = require("three-stl-loader")(THREE);
const TrackballControls = require("./../viewer3D/TrackballControls");
// var JSZip = require('JSZip')
// var FileSaver = require('file-saver');

export default {
  name: "viewer3Dconstructor",
  components: { preloader, wattermark, stlDownloadModal },
  data() {
    return {
      endpoint: "viewer_3d/",
      profileStatusEndpoint: "profile/status/",
      isPremium: false,

      isStlDownload: false,

      isLoading: false,
      isFileControls: true,
      isFileListControls: true,

      scale: 0.35,

      constuctorItems: models,
      currentSelect: "",
      constuctorTop: {
        src: "topConstructorBackground.png",
        width: 600,
        height: 440,
        isActive: false,
        stl: {
          name: "Maxillary",
          url: "https://s3.eu-central-1.amazonaws.com/dentabula/3d_constructor/models/view/Maxillary.stl",
          options: { material: "gum", opacity: 100 },
          progress: 0,
        },
      },
      constuctorBottom: {
        src: "bottomConstructorBackground.png",
        width: 600,
        height: 390,
        offset: 440,
        isActive: false,
        stl: {
          name: "Mandibular",
          url: "https://s3.eu-central-1.amazonaws.com/dentabula/3d_constructor/models/view/Mandibular.stl",
          options: { material: "gum", opacity: 100 },
          progress: 0,
        },
      },

      scene: null,
      camera: null,
      cameraZoom: 0.3,
      controls: null,
      directionalLight: null,
      renderer: null,
      rendererContainer: null,
      mainGroup: null,
      centerPoint: {
        x: 0,
        y: 0,
        z: 0,
        xArray: [],
        yArray: [],
        zArray: [],
      },
      animationFrame: null,
      stlFiles: [],

      stlFilesDownload: [],
    };
  },
  computed: {
    isLoggedIn() {
      return this.$store.getters.isLoggedIn;
    },
    loadingProgress() {
      let result = this.stlFiles.reduce((sum, current) => sum + current.progress, 0);

      if (result === 0) {
        return 100;
      } else {
        return (result / this.stlFiles.length).toFixed();
      }
    },
    constuctorItemsActive: function () {
      return Object.keys({ ...this.constuctorItems })
        .filter((el) => el === this.currentSelect)
        .reduce((list, el) => {
          return [...list, this.constuctorItems[el]];
        }, []);
      // return [...this.constuctorItems].filter((el, index) => index === this.currentSelect);
    },
  },
  methods: {
    getProfile() {
      this.$http.post(this.profileStatusEndpoint).then((response) => {
        this.isPremium = response.data.isPremium;
      });
    },
    init() {
      this.scene = new THREE.Scene();
      this.addCamera();
      //this.addAxes();
      this.addRenderer();
      this.addControls();
      this.addLight();
      this.addMainGroup();
      this.chekStlFiles();
      this.animate();
    },
    addCamera() {
      // Обьявление камеры
      let width = this.rendererContainer.clientWidth * this.cameraZoom;
      let height = this.rendererContainer.clientHeight * this.cameraZoom;
      this.camera = new THREE.OrthographicCamera(width / -2, width / 2, height / 2, height / -2, 1, 1000);

      // Old perspective camera
      // this.camera = new THREE.PerspectiveCamera(45, width / height, 1, 1000);

      this.camera.position.set(-75, 75, 75);
      this.camera.lookAt(this.scene.position);

      this.camera.updateProjectionMatrix();
    },
    addAxes() {
      // Добавление осей координат (Опционально)
      let axes = new THREE.AxisHelper(20);
      this.scene.add(axes);
    },
    addRenderer() {
      // Обьявление области рендеринга
      this.renderer = new THREE.WebGLRenderer({ alpha: true });
      this.renderer.setSize(this.rendererContainer.clientWidth, this.rendererContainer.clientHeight);
      this.rendererContainer.appendChild(this.renderer.domElement);
      this.renderer.setClearColor(0x423c63, 0);
    },
    addControls() {
      // Добавление управления
      this.controls = new TrackballControls(this.camera, this.rendererContainer);
      this.controls.rotateSpeed = 4.0;
      this.controls.zoomSpeed = 4.0;
      this.controls.panSpeed = 0.8;
      this.controls.noZoom = false;
      this.controls.noPan = false;
      this.controls.staticMoving = true;
      this.controls.dynamicDampingFactor = 0.3;

      this.controls.addEventListener("change", this.render);
    },
    addLight() {
      // Добавление источников света
      this.directionalLight = new THREE.DirectionalLight(0xffffff, 0.9);
      this.scene.add(this.directionalLight);
      let light = new THREE.AmbientLight(0x404040); // soft white light
      this.scene.add(light);
    },
    addMainGroup() {
      // Добавление основной группы
      this.mainGroup = new THREE.Group();
      this.mainGroup.updateMatrixWorld(true);
      this.scene.add(this.mainGroup);
    },

    addToMainMesh(stlFile, index) {
      // Добавление файла в MainMesh
      let manager = new THREE.LoadingManager();
      manager.onStart = function () {};
      manager.onLoad = function () {};
      manager.onProgress = function () {};
      manager.onError = function () {};

      const loader = new STLLoader(manager);
      loader.load(
        stlFile.url,
        (geometry) => {
          geometry.computeBoundingSphere();

          let materialName =
            typeof stlFile.options !== undefined && typeof stlFile.options.material !== undefined
              ? stlFile.options.material
              : "default";
          let material = this.getItemMaterial(materialName);

          let mesh = new THREE.Mesh(geometry, material);
          mesh.name = stlFile.name;
          mesh.castShadow = true;
          mesh.receiveShadow = true;

          this.mainGroup.add(mesh);

          if (typeof stlFile.options.parent == "string" && stlFile.options.parent) {
            if (!this.stlFilesGroups[stlFile.options.parent]) {
              this.stlFilesGroups[stlFile.options.parent] = { value: "100", models: [stlFile.name] };
            } else {
              this.stlFilesGroups[stlFile.options.parent].models.push(stlFile.name);
            }
          }

          let opacityType =
            typeof stlFile.options !== undefined && typeof stlFile.options.opacity !== undefined
              ? stlFile.options.opacity
              : "0";
          this.setOpacity(stlFile.name, opacityType);

          this.render();
        },
        (e) => {
          this.progressLoader(e.loaded, e.total, index);
        },
      );
    },

    getItemMaterial(materialType = null) {
      let material = null;
      switch (materialType) {
        case "bone":
          material = new THREE.MeshStandardMaterial({
            color: 0xffefc9,
            roughness: 0.8,
            metalness: 0.3,
            side: THREE.DoubleSide,
          });
          break;
        case "enamel":
          material = new THREE.MeshStandardMaterial({
            color: 0xe3dccc,
            roughness: 0.4,
            metalness: 0.05,
            side: THREE.DoubleSide,
          });
          break;
        case "gum":
          material = new THREE.MeshStandardMaterial({
            color: 0xac6870,
            roughness: 0.3,
            metalness: 0.05,
            side: THREE.DoubleSide,
          });
          break;
        case "steel":
          material = new THREE.MeshStandardMaterial({
            color: 0xe1e1e1,
            roughness: 0.15,
            metalness: 0.35,
            side: THREE.DoubleSide,
          });
          break;
        case "green":
          material = new THREE.MeshStandardMaterial({
            color: 0x4cbb17,
            roughness: 0.3,
            metalness: 0.05,
            side: THREE.DoubleSide,
          });
          break;
        case "blue":
          material = new THREE.MeshStandardMaterial({
            color: 0x87cefa,
            roughness: 0.3,
            metalness: 0.05,
            side: THREE.DoubleSide,
          });
          break;
        case "pink":
          //material = new THREE.MeshStandardMaterial({color: 0xdb7093, roughness: 0.3, metalness: 0.05, side:THREE.DoubleSide}); //old
          material = new THREE.MeshStandardMaterial({
            color: 0xea899a,
            roughness: 0.3,
            metalness: 0.05,
            side: THREE.DoubleSide,
          }); // new
          break;
        case "purple":
          // old 0x7851a9 0xdda0dd
          material = new THREE.MeshStandardMaterial({
            color: 0xb664c0,
            roughness: 0.3,
            metalness: 0.05,
            side: THREE.DoubleSide,
          }); // new
          break;
        case "orange":
          // old 0xff8243 0xefa94a
          material = new THREE.MeshStandardMaterial({
            color: 0xe2b67b,
            roughness: 0.3,
            metalness: 0.05,
            side: THREE.DoubleSide,
          }); // new
          break;
        case "tab":
          material = new THREE.MeshStandardMaterial({
            color: 0x4cbb17,
            roughness: 0.3,
            metalness: 0.05,
            side: THREE.DoubleSide,
          }); // new
          break;
        default:
          material = new THREE.MeshStandardMaterial({
            color: 0xffffff,
            roughness: 1,
            metalness: 1,
            side: THREE.DoubleSide,
          });
      }
      return material;
      //return new THREE.MeshStandardMaterial({color: 0xFF8243, side:THREE.DoubleSide});
    },
    selectOpacity(event) {
      this.setOpacity(event.target.dataset.modelName, event.target.value);
      this.render();
    },
    setOpacity(modelName, opacityType) {
      let editModel = this.mainGroup.getObjectByName(modelName);
      let opacity = parseInt(opacityType) / 100;
      if (opacity === 1) {
        editModel.material.transparent = false;
        editModel.material.opacity = 1;
        editModel.visible = true;
      } else if (opacity === 0) {
        editModel.visible = false;
      } else {
        editModel.material.transparent = true;
        editModel.material.opacity = opacity;
        editModel.visible = true;
      }
    },
    calcCenter() {
      // Расчет центра всех моделей
      this.centerPoint = { x: 0, y: 0, z: 0, xArray: [], yArray: [], zArray: [] };
      for (let i = 0; i < this.mainGroup.children.length; i++) {
        this.centerPoint.xArray.push(this.mainGroup.children[i].geometry.boundingSphere.center.x);
        this.centerPoint.yArray.push(this.mainGroup.children[i].geometry.boundingSphere.center.y);
        this.centerPoint.zArray.push(this.mainGroup.children[i].geometry.boundingSphere.center.z);
      }
      if (this.centerPoint.xArray.length) {
        this.centerPoint.x =
          -[].reduce.call(this.centerPoint.xArray, function (p, c) {
            return c + p;
          }) / this.centerPoint.xArray.length;
      }
      if (this.centerPoint.yArray.length) {
        this.centerPoint.y =
          -[].reduce.call(this.centerPoint.yArray, function (p, c) {
            return c + p;
          }) / this.centerPoint.yArray.length;
      }
      if (this.centerPoint.zArray.length) {
        this.centerPoint.z =
          -[].reduce.call(this.centerPoint.zArray, function (p, c) {
            return c + p;
          }) / this.centerPoint.zArray.length;
      }
      this.mainGroup.position.set(this.centerPoint.x, this.centerPoint.y, this.centerPoint.z);
    },
    render() {
      // Ререндеринг сцены
      this.renderer.render(this.scene, this.camera);
      this.directionalLight.position.copy(this.camera.position);
      this.calcCenter();
    },
    progressLoader(loaded, total, index) {
      let progress = Math.round((loaded / total) * 100);
      this.stlFiles[index] = Object.assign({}, this.stlFiles[index], {
        progress: progress,
      });
      this.$set(this.stlFiles, index, this.stlFiles[index]);
    },
    animate() {
      // Анимация сцены
      this.animationFrame = requestAnimationFrame(this.animate);
      this.renderer.render(this.scene, this.camera);
      this.controls.update();
    },
    resize() {
      if (this.camera && this.renderer) {
        this.renderer.setSize(this.rendererContainer.clientWidth, this.rendererContainer.clientHeight);

        let width = this.rendererContainer.clientWidth * this.cameraZoom;
        let height = this.rendererContainer.clientHeight * this.cameraZoom;
        this.camera.left = width / -2;
        this.camera.right = width / 2;
        this.camera.top = height / 2;
        this.camera.bottom = height / -2;

        this.camera.updateProjectionMatrix();
      }
    },

    removeFromMainGroup(name) {
      let selectedObject = this.mainGroup.getObjectByName(name);
      this.mainGroup.remove(selectedObject);
    },
    chekStlFiles() {
      // Проверка рендер-массива с maingroup рендера

      // Функция нахождения отличий
      Array.prototype.diff = function (a) {
        return this.filter(function (i) {
          return a.indexOf(i) < 0;
        });
      };

      // Сравнение массивов на удаление лишних элементов
      let stlListNames = this.stlFiles.map((el) => {
        return el.name;
      });

      let mainGroupRemove = this.mainGroup.children.map((el) => {
        return el.name;
      });

      let diff = mainGroupRemove.diff(stlListNames);

      for (let i = 0; i < diff.length; i++) {
        this.removeFromMainGroup(diff[i]);
      }

      // Добавление отсутствующих файлов
      for (let i = 0; i < this.stlFiles.length; i++) {
        if (!this.mainGroup.getObjectByName(this.stlFiles[i].name)) {
          this.addToMainMesh(this.stlFiles[i], i);
        } else {
          this.progressLoader(100, 100, i);
        }
      }
    },
    getBaseSTLArray() {
      // Импорт базового списка stl файлов в рендер-массив
      let topSlice = 0;
      let bottomSlice = 0;
      this.stlFiles = [];
      if (!this.constuctorTop.isActive && !this.constuctorBottom.isActive) {
        return false;
      }
      // Ограничение выборки доступных файлов для рендеринга на основе верхней или нижней части челюсти
      if (this.constuctorTop.isActive) {
        this.stlFiles.push(this.constuctorTop.stl);
        topSlice = 0;
      } else {
        topSlice = 30;
      }
      if (this.constuctorBottom.isActive) {
        this.stlFiles.push(this.constuctorBottom.stl);
        bottomSlice = 48;
      } else {
        bottomSlice = 30;
      }

      // Перебор всех доступных элементов
      for (let item in this.constuctorItems) {
        // Проверка включения в выборку
        if (item > topSlice && item < bottomSlice) {
          // Добавление основного элемента
          if (this.constuctorItems[item][this.constuctorItems[item].current].stl !== undefined) {
            this.stlFiles.push(this.constuctorItems[item][this.constuctorItems[item].current].stl);
          }

          // Добавление дочернего элемента
          if (this.constuctorItems[item][this.constuctorItems[item].current].hasChild) {
            this.stlFiles.push(
              this.constuctorItems[item][this.constuctorItems[item][this.constuctorItems[item].current].child].stl,
            );
          }
        }
      }
    },

    activateMainModel() {
      if (this.currentSelect > 30) {
        this.constuctorBottom.isActive = true;
      } else if (this.currentSelect > 0) {
        this.constuctorTop.isActive = true;
      }
      this.rerender();
    },
    selectConstructorItem(type) {
      this.constuctorItems[this.currentSelect].current = type;
      this.constuctorItems[this.currentSelect]["missing"].hasChild = false;
      this.constuctorItems[this.currentSelect]["reduced"].hasChild = false;
      this.constuctorItems[this.currentSelect]["partically_reduced"].hasChild = false;
      this.rerender();
    },
    selectConstructorItemChild(type) {
      this.constuctorItems[this.currentSelect].current = type;
      this.constuctorItems[this.currentSelect][this.constuctorItems[this.currentSelect].current].hasChild = !this
        .constuctorItems[this.currentSelect][this.constuctorItems[this.currentSelect].current].hasChild;
      let parrents = ["missing", "reduced", "partically_reduced"];
      parrents.splice(parrents.indexOf(this.constuctorItems[this.currentSelect].current), 1);
      for (let i = 0; i < parrents.length; i++) {
        this.constuctorItems[this.currentSelect][parrents[i]].hasChild = false;
      }
      this.rerender();
    },
    rerender() {
      this.getBaseSTLArray();
      this.chekStlFiles();
    },

    getCurrentFiles() {
      this.isStlDownload = true;

      this.stlFilesDownload = this.stlFiles.map((item) => {
        item.url = item.url.replace("/view/", "/download/");
        return item;
      });
    },
  },
  mounted() {
    this.rendererContainer = this.$refs.rendererContainer;
    if (!isWebglEnabled) {
      console.log("webGL is not supported!");
    } else {
      // this.getBaseSTLArray();
      this.init();
    }
  },
  created() {
    this.getProfile();
    window.addEventListener("resize", this.resize, false);
  },
  destroyed() {
    window.removeEventListener("resize", this.resize, false);
    cancelAnimationFrame(this.animationFrame);
  },
};
</script>

<style lang="sass">
#viewer3Dconstructor
	.constructorLoader
		top: 65px
		left: 275px
	.selectModel
		display: flex
		align-items: center
		text-align: center
		color: #ffffff
		// font-size: 1.2rem
		font-weight: bold
		& > img
			display: block
			width: 18px
			height: 18px
			margin-right: 0.5rem
	.downloadAll
		display: flex
		align-items: center
		text-align: center
		padding: 0.5rem 0.5rem
		color: #ffffff
		cursor: pointer
		border-radius: 4px
		border: 1px solid #fff
		transition: all 0.3s
		&:hover
			background: #494a8f
		& > img
			display: block
			width: 18px
			height: 18px
			margin-right: 0.5rem
	.permissionDenied
		display: flex
		color: #fff
		& > img
			display: block
			width: 18px
			height: 18px
			margin-right: 0.5rem
		& a
			color: #fff
			text-decoration: underline
			font-weight: bold
#viewer3Dconstructor
	.controls
		display: flex
		align-items: center
		justify-content: space-between
		background-color: #12123d
		padding: 0 0.5rem
		height: 50px
		border-bottom: 2px solid #494a8f
		.viewer3Dlogo
			display: flex
			align-items: center
			justify-content: center
			height: inherit
			img
				display: block
				height: 30px
		.viewer3Dclose
			display: flex
			align-items: center
			justify-content: center
			height: inherit
			color: #fff
	.manipulator
		display: flex
		flex-direction: column
		position: absolute
		top: 50px
		left: 0
		height: calc(100vh - 50px)
		z-index: 5
		transition: all 0.2s
		&.active
			background: rgba(51, 51, 51, 0.75)
		.manipulatorToogle
			display: flex
			align-items: center
			justify-content: space-between
			color: #fff
			padding: 0.5rem 0.5rem
			border-bottom: 1px solid #fff
		.manipulatorControls
			display: flex
			flex-direction: column
			height: 100%
			.visualForm
				display: flex
				justify-content: center
				.vfT, .vfB
					opacity: 0.6
					&.active
						opacity: 1
				.toothItem
					opacity: 1
					&.empty
						opacity: 0
			.staticForm
				.jawSelect
					display: flex
					flex-direction: column
					border-bottom: 1px solid #fff
					.jawSelectBtn
						display: flex
						justify-content: center
						align-items: center
						padding: 0.25rem 0
						color: #fff
						font-size: 0.8rem
						& label
							margin-right: 0.25rem
				.toothSelect
					display: flex
					justify-content: center
					align-items: center
					color: #fff
					padding: 0.5rem 0
					& label
						margin-right: 0.25rem

				.constructorTypeBtn
					display: flex
					align-items: center
					color: #fff
					width: 100%
					height: 44px
					border-top: 1px solid #ddd
					cursor: pointer
					&:last-child
						border-bottom: 1px solid #ddd
					& .constructorTypeBtnMain
						display: flex
						align-items: center
						flex-basis: 60%
						height: 100%
						padding: 0 0.5rem
						&.singleBtn
							flex-basis: 100%
						&.active
							background: #828282
						.constructorTypeBtnTitle
							display: flex
							align-items: center
							font-size: 0.55rem
							.toothSelectIcon
								display: block
								width: 35px
								height: 35px
								margin-right: 0.25rem
					& .constructorTypeBtnChild
						display: flex
						align-items: center
						justify-content: space-between
						flex-basis: 40%
						height: 100%
						padding: 0 0.5rem
						font-size: 0.6rem
						border-left: 1px solid red

	.fileListControls
		display: block
		position: absolute
		top: 50px
		right: 0
		z-index: 5
		transition: all 0.2s
		&.active
			background: #fff
			min-width: 300px
			.fileListToogle
				border-color: #ddd
				span
					display: block
		.fileListToogle
			display: flex
			align-items: center
			color: #494a8f
			padding: 0.5rem 0.5rem
			cursor: pointer
			border: 1px solid transparent
			justify-content: space-between
			@media screen and (max-width: 768px)
				span
					display: none
			.leftIcon
				margin-right: 0.5rem
		.fileListElements
			display: flex
			flex-direction: column
			border: 1px solid #ddd
			border-top: none
			overflow-y: scroll
			max-height: calc(100vh - 120px)
			.emptyFiles
				display: flex
				align-items: center
				justify-content: center
				padding: .5rem 0
			.fileListItem
				display: flex
				justify-content: space-between
				padding: 0 10px
				border-bottom: 1px solid #ddd
				min-height: 45px
				align-items: center
				&:last-child
					border-bottom: none
				.fileListItemOpacity
					display: flex
					.opacityIcon
						content: ''
						display: block
						width: 20px
						height: 30px
						background-size: cover
						&.opacity_0
							background-image: url('../../assets/images/opacity_0.jpg')
						&.opacity_100
							background-image: url('../../assets/images/opacity_100.jpg')
					input.opacityType
						width: 70px
						margin: 0 5px

				.fileListItemColor
					display: flex
					align-items: center
					margin-left: 5px
					a
						display: inline-block
						height: 15px
						width: 15px
						margin: 2px
						cursor: pointer
						&.active
							border: 2px solid #007bff
						&:nth-child(1)
							background-color: #87CEFA
						&:nth-child(2)
							background-color: #DB7093
						&:nth-child(3)
							background-color: #7851A9
						&:nth-child(4)
							background-color: #FF8243

	#rendererContainer
		display: block
		position: relative
		height: calc(100vh - 50px)
		box-sizing: border-box
		width: 100%
		canvas
			display: block
			background: rgb(176,174,196)
			background: radial-gradient(ellipse at center, rgba(239,239,244,1) 0%,rgba(191,191,196,1) 100%)
</style>
